import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
import { getApi } from "../Helper/api";
export default function AdminSetting() {
  const title = [
    "id",
    "boosts",
    "likes",
    "super_likes",
    "diamonds",
    "rewinds",
    "mile",
    "card_limit",
    "precious_limit",
    "status",
  ];
  const formConfig = {
    fields: [
      {
        name: "boosts",
        label: "Boosts",
        type: "text",
        placeholder: "enter boosts",
      },
      {
        name: "likes",
        label: "Likes",
        type: "text",
        placeholder: "enter likes",
      },
      {
        name: "super_likes",
        label: "Super Likes",
        type: "text",
        placeholder: "enter super likes",
      },
      {
        name: "diamonds",
        label: "Diamonds",
        type: "text",
        placeholder: "enter diamonds",
      },
      {
        name: "rewinds",
        label: "Rewinds",
        type: "text",
        placeholder: "enter rewinds",
      },
      {
        name: "card_limit",
        label: "Card Limit",
        type: "text",
        placeholder: "enter card limit",
      },
      {
        name: "precious_limit",
        label: "Precious Limit",
        type: "text",
        placeholder: "enter precious limit",
      },
      {
        name: "mile",
        label: "Mile",
        type: "text",
        placeholder: "enter mile",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      boosts: Yup.number().required("boosts Required in numbers"),
      diamonds: Yup.number().required("diamonds Required in numbers"),
      rewinds: Yup.number().required("rewinds Required in numbers"),
      super_likes: Yup.number().required("super_likes Required in numbers"),
      likes: Yup.number().required("likes Required in numbers"),
      mile: Yup.number().required("diamonds Required in numbers"),
      card_limit: Yup.number().required("diamonds Required in numbers"),
      precious_limit: Yup.number().required("diamonds Required in numbers"),
    }),
  };

  const initialValues = {
    boosts: "",
    diamonds: "",
    rewinds: "",
    super_likes: "",
    likes: "",
    status: 0,
    precious_limit: "",
    card_limit: "",
    mile: "",
  };
  return (
    <div>
      <TableDisplay
        category="Admin Setting"
        tableurl="adminsetting"
        title={title}
        deleteurl="delete-adminsetting"
        deleteallurl="all-delete-adminsetting"
        addurl="add-adminsetting"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-adminsetting"
        updatteurl="edit-adminsetting"
        serachCategory="adminsetting"
        statusurl="status-adminsetting"
        axiosHeader={false}
      />
    </div>
  );
}
