import React from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function TopupCategory() {
  const title = ["id", "name", "status"];
  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("name Required"),
    }),
  };

  const initialValues = {
    name: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Topup Category"
        tableurl="topupcategory"
        title={title}
        deleteurl="delete-topupcategory"
        deleteallurl="all-delete-topupcategory"
        addurl="add-topupcategory"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-topupcategory"
        updatteurl="edit-topupcategory"
        serachCategory="topupcategory"
        statusurl="status-topupcategory"
        axiosHeader={false}
      />
    </div>
  );
}
