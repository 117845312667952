import React from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function Country() {
  const title = ["id", "country", "flag", "dial_code", "code", "status"];
  const formConfig = {
    fields: [
      {
        name: "country",
        label: "Country",
        type: "text",
        placeholder: "enter country",
      },
      {
        name: "dial_code",
        label: "Dial Code",
        type: "text",
        placeholder: "enter Dial Code",
      },
      {
        name: "code",
        label: "Code",
        type: "text",
        placeholder: "enter Code",
      },
      {
        name: "flag",
        label: "Flag",
        type: "file",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      country: Yup.string()
        .required("country name Required")
        .matches(/^[A-Za-z]+$/, "Name must contain only alphabetic characters"),
      dial_code: Yup.string()
        .matches(/^\+\d+$/, "Dial code is not valid add + first")
        .required("country dial code Required"),
      code: Yup.string()
        .matches(/^\D+$/, "Invalid code add string")
        .required("String is required"),
      flag: Yup.mixed().required("Please upload an image"),
    }),
  };
  const initialValues = {
    country: "",
    dial_code: "",
    code: "",
    flag: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Country"
        tableurl="country"
        title={title}
        deleteurl="delete-country"
        deleteallurl="all-delete-country"
        addurl="add-country"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-country"
        updatteurl="edit-country"
        serachCategory="country"
        statusurl="status-country"
        axiosHeader={true}
      />
    </div>
  );
}
