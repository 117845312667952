import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
import { getApi } from "../Helper/api";
export default function Basics() {
  const title = ["id", "basic", "category_id", "icons", "status"];
  const [category, setCategory] = useState([]);
  const convertedData = category?.map((item) => ({
    id: item.id,
    name: item.category,
  }));
  const formConfig = {
    fields: [
      {
        name: "basic",
        label: "Basic",
        type: "text",
        placeholder: "enter basic",
      },
      {
        name: "icons",
        label: "Icon",
        type: "file",
      },
      {
        name: "category_id",
        label: "Category",
        type: "select",
        options: convertedData && convertedData,
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      basic: Yup.string().required("Basic name Required"),
      icons: Yup.mixed().required("Please upload an image"),
      category_id: Yup.string().required("Category id Required"),
    }),
  };

  const initialValues = {
    basic: "",
    category_id: "",
    icons: "",
    status: 0,
  };
  const fetchData = async () => {
    await getApi("category", true).then((res) => {
      setCategory(res.data);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <TableDisplay
        category="Basics"
        tableurl="basics"
        title={title}
        deleteurl="delete-basics"
        deleteallurl="all-delete-basics"
        addurl="add-basics"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-basics"
        updatteurl="edit-basics"
        serachCategory="basics"
        statusurl="status-basics"
        axiosHeader={true}
      />
    </div>
  );
}
