import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Pagination from "./Pagination";
import { getApi, postApi } from "../Helper/api";
import VerifyIcon from "../assets/images/verify.svg";
import unverifyedIcon from "../assets/images/unverifyed.svg";
import menuIcon from "../assets/images/menu.svg";
import blockIcon from "../assets/images/block.svg";
import eyeIcon from "../assets/images/eye.svg";
import updateIcon from "../assets/images/update.svg";
import deleteIcon from "../assets/images/delete.svg";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Highlighter from "react-highlight-words";
import { successNotify } from "./SuccessErrorMessage";
export default function Users() {
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(0);
  const [TableMenuOpen, setIsTableMenuOpen] = useState(false);
  const [isNumberType, setIsNumberType] = useState(-1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteid, setdeleteid] = useState(0);
  const [id, setId] = useState();
  const [fakeUser, setFakeUser] = useState("");
  const [genderType, setgenderType] = useState("");
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = (id) => {
    setdeleteid(id);
    setShowDelete(true);
  };
  /*************Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const highlightText = (text, search) => (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={[search]}
      autoEscape={true}
      textToHighlight={text}
    />
  );
  /***************** */
  useEffect(() => {
    getAll();
  }, [currentPage, search, fakeUser, genderType]);
  const handleDelete = async (id) => {
    try {
      const res = await getApi(`delete-user/${id}`, true);
      if (res.status === 200) {
        getAll();
        // errorNotify(res.message);
        handleDeleteClose();
        if (userData.length === 0) {
          // setCurrentPage(currentPage - 1);
        }
        // setCurrentPage(currentPage);
      }
    } catch (err) {
      console.log(err);
    }
  };
  let getAll = async () => {
    // let url = `get-all-users?page=${currentPage}&search=${search}&fake=${fakeUser}`;
    let url = `get-all-users?page=${currentPage}`;
    if (search !== "" || search !== null || search !== undefined)
      url += `&search=${search}`;
    if (fakeUser !== null || fakeUser !== undefined || fakeUser !== "")
      url += `&fake=${fakeUser}`;
    if (genderType !== "" || genderType !== undefined || genderType !== null)
      url += `&gender=${genderType}`;
    await getApi(url, true)
      .then((res) => {
        // console.log("🚀 ~ .then ~ data:", res);
        if (res.status === 200) {
          setUserData(res.data);
          setTotalPages(res.totalPages);
          setCount(res.count);
        }
      })
      .catch((err) => {
        console.log("Fetching data errror", err);
        // if (err?.response?.status === 403) {
        // setError(err.response.data.message);
        // }
      });
  };

  useEffect(() => {
    const handlePageClick = async (event) => {
      if (event?.target?.className === "tbl-menu") {
        return;
      } else {
        setIsNumberType(0);
      }
    };
    // Add click event listener to the document
    document.addEventListener("click", handlePageClick);
    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handlePageClick);
    };
  }, []);

  const handleBlocked = async (id, status) => {
    let url = `block-user?user_id=${id}`;
    await postApi(url, { is_blocked_by_admin: status === 1 ? 0 : 1 }, true)
      .then(async ({ data }) => {
        const user = await Promise.all(
          userData?.map((val) => {
            if (val.id === id) {
              val.is_blocked_by_admin = status === 1 ? 0 : 1;
            }
            return val;
          })
        );
        setUserData(user);
      })
      .catch((err) => {
        console.log("Fetching data error", err);
      });
  };
  const handleStatusChange = async (statuss, id) => {
    const newdata = {
      is_verified: statuss,
    };
    await postApi(`user-status-change/${id}`, newdata, true, false)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.message);
          getAll();
        }
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="title d-flex justify-content-between align-middle align-items-center">
          <h4 className="d-flex justify-content-between marginright align-items-center">
            User
          </h4>
          {count === 1 ? (
            <h6 className="m-0">( {count} Record )</h6>
          ) : count > 0 ? (
            <h6>( {count} Records )</h6>
          ) : null}
        </div>
        <div className="w-75 d-flex justify-content-end align-items-center">
          <div className="dropdown-wrap d-flex">
            {/* <label className='popup__label marginright'>Show</label> */}
            <div
              className="shownumber custon position-relative marginright col-3"
              style={{ width: 100 }}
            >
              <select
                onChange={(e) => {
                  setgenderType(e.target.value);
                }}
                className="my-select"
              >
                <option value="">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Others</option>
              </select>
            </div>
          </div>
          <div className="dropdown-wrap d-flex">
            {/* <label className='popup__label marginright'>Show</label> */}
            <div
              className="shownumber custon position-relative marginright col-3"
              style={{ width: 130 }}
            >
              <select
                onChange={(e) => {
                  setFakeUser(e.target.value);
                }}
                className="my-select"
              >
                <option value="">Fake & Real</option>
                <option value={1}>Fake</option>
                <option value={0}>Real</option>
              </select>
            </div>
          </div>


          <div className="search marginright w-50">
            <input
              className="search__input marginright popup__input"
              onChange={(e) => {
                setSearch(e.target.value); setCurrentPage(1)
              }}
              type="text"
              placeholder={`Search User`}
            />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="black"
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
              />
            </svg>
          </div>
          <Link className="mybtn" to="/add-user">
            Add User
          </Link>
        </div>
      </div>
      <Table className="data_table" responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>full name</th>
            <th>email</th>
            <th>Phone number</th>
            <th className="text-center" style={{ width: "110px" }}>
              country code
            </th>
            <th className="text-center" style={{ width: "80px" }}>
              gender
            </th>
            <th className="text-center" style={{ width: "110px" }}>
              Date of birth
            </th>
            <th className="text-center" style={{ width: "65px" }}>
              age
            </th>
            <th className="text-center" style={{ width: "105px" }}>
              login type
            </th>
            <th className="text-center" style={{ width: "80px" }}>
              verified
            </th>
            <th className="text-center" style={{ width: "120px" }}>
              verified type
            </th>
            <th className="text-center" style={{ width: "70px" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {userData.length !== 0 ? (
            userData?.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val.id}</td>
                  <td className="user-full-name" onClick={() => {setId(val.id); window.location.href = `/user/${val.id}`;}}>{highlightText(val.fullname, search)}</td>
                  <td>{highlightText(val.email, search)}</td>
                  <td>{highlightText(val.mobile_num !== null ? val.mobile_num : "--",search)}</td>
                  <td className="text-center">{highlightText(val.country_code, search)}</td>
                  <td className="text-center">{highlightText(val.gender, search)}</td>
                  <td className="text-center">{highlightText(val.birth_date, search)}</td>
                  <td className="text-center">{val.age}</td>
                  <td className="text-center">{highlightText(val.login_type === 1 ? "Google" : "Mobile Number",search)}</td>
                  <td className="text-center">{val.is_verified  ? (<img src={VerifyIcon} alt="verify icon"/>)  : (<img src={unverifyedIcon} alt="unverifyed icon"/>)}</td>
                  <td className="text-center">{highlightText(val.is_verified_type === 1 ? "Google" : "Mobile Number",search)}</td>
                  <td className="text-center">
                    <div className="action-btn tbl-menu" onClick={() => {setIsTableMenuOpen(!TableMenuOpen); setIsNumberType(val.id);}}>
                      <img src={menuIcon} alt="menu icon" className="tbl-menu"/>
                      <ul className={`action-menu ${isNumberType !== val.id && "d-none"}`}>
                        <li className="action-item-menu" onClick={() => handleBlocked(val.id, val.is_blocked_by_admin)}>
                          <span>
                            <img src={blockIcon} alt="block icon" />
                          </span>&nbsp;&nbsp;
                          {val.is_blocked_by_admin === 1 ? "unblock" : "Block"}
                        </li>
                        <li className="action-item-menu"  onClick={() => {setId(val.id); window.location.href = `/user/${val.id}`;}}>
                          <span>
                            <img src={eyeIcon} alt="eye icon" />
                          </span>
                          &nbsp;&nbsp;View
                        </li>
                        {val?.is_fake ? (
                          <>
                            <li className="action-item-menu" onClick={() => {setId(val.id); window.location.href = `/edit-user/${val.id}`;}}>
                              <span>
                                <img src={updateIcon} alt="update icon" />
                              </span>
                              &nbsp;&nbsp;Update
                            </li>
                            <li className="action-item-menu" onClick={() => handleDeleteShow(val.id)}>
                              <span>
                                <img src={deleteIcon} alt="delete icon" />
                              </span>
                              &nbsp;&nbsp;Delete
                            </li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={12} className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {totalPages > 1 ? (
        <Pagination currentPage={currentPage} totalPages={totalPages} onNext={handleNextPage} onPrev={handlePrevPage} handlePageChange={handlePageChange}/>
      ) : null}
      <Modal show={showDelete} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="mybtn black" onClick={handleDeleteClose}>Cancel</Button>
          {selectedItems.length === 0 ? (
            <Button variant="primary" className="mybtn" onClick={() => handleDelete(deleteid)}>Delete</Button>
          ) : (
            <Button variant="primary" className="mybtn">Delete</Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
