import React from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function CategoryBasics() {
  const title = ["id", "category", "status"];
  const formConfig = {
    fields: [
      {
        name: "category",
        label: "Category",
        type: "text",
        placeholder: "enter category",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      category: Yup.string().required("category name Required"),
    }),
  };

  const initialValues = {
    category: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Category"
        tableurl="category"
        title={title}
        deleteurl="delete-category"
        deleteallurl="all-delete-category"
        addurl="add-category"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-category"
        updatteurl="edit-category"
        serachCategory="category"
        statusurl="status-category"
        axiosHeader={false}
      />
    </div>
  );
}
