import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { Provider, useSelector } from "react-redux";
import store from "../Redux/store";
import Navbar from "./Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Index({ children }) {
  const [isChecked, setIsChecked] = useState(true);
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Provider store={store}>
      <div>
        <input
          type="checkbox"
          id="menu"
          defaultChecked={isChecked}
          onChange={handleChange}
        />
        <Sidebar />
        <Navbar />
        {/* <div className="main-section">{children}</div> */}
        <div className="main-section">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          {/* Same as */}
          <ToastContainer />
          {children}
        </div>
      </div>
    </Provider>
  );
}
