import React from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function Package() {
  const title = [
    "id",
    "title",
    "description",
    "monthly_boosts",
    "weekly_super_likes",
    "status",
  ];
  const formConfig = {
    fields: [
      {
        name: "title",
        label: "Title",
        type: "text",
        placeholder: "Enter Title",
      },
      {
        name: "description",
        label: "Description Code",
        type: "text",
        placeholder: "Enter Description",
      },
      {
        name: "monthly_boosts",
        label: "Monthly Boosts",
        type: "text",
        placeholder: "Enter Monthly Boosts",
      },
      {
        name: "weekly_super_likes",
        label: "Weekly Super Likes",
        type: "text",
        placeholder: "Enter Weekly Super Likes",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      title: Yup.string().required("Title Required"),
      description: Yup.string().required("Description Required"),
      monthly_boosts: Yup.number().required(
        "Monthly Boosts Required in numbers"
      ),
      weekly_super_likes: Yup.number().required(
        "Weekly Super Likes Required in numbers"
      ),
    }),
  };
  const initialValues = {
    title: "",
    description: "",
    monthly_boosts: "",
    weekly_super_likes: "",
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Package"
        tableurl="package"
        title={title}
        deleteurl="delete-package"
        deleteallurl="all-delete-package"
        addurl="add-package"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-package"
        updatteurl="edit-package"
        serachCategory="package"
        statusurl="status-package"
        axiosHeader={false}
      />
    </div>
  );
}
