import React, { useEffect, useState } from "react";
import {Button, Dropdown, DropdownButton, Form, Modal} from 'react-bootstrap';

import { useFormik } from "formik";
import * as Yup from "yup";

import { getApi, postApi } from "../Helper/api";

import { errorNotify, successNotify } from "./SuccessErrorMessage";

import { ReactComponent as DeleteSvg } from "../assets/images/delete.svg";
import { ReactComponent as EditPlanSvg } from "../assets/images/edit-plans.svg";
import { ReactComponent as HeadingSvg } from "../assets/images/heading.svg";
import { ReactComponent as SuperLikeSvg } from "../assets/images/super_like.svg";
import { ReactComponent as BoostSvg } from "../assets/images/boost.svg";
import { ReactComponent as DiamondSvg } from "../assets/images/diamond.svg";

import likeInf from "../assets/images/like_inf.png";
import superLike from "../assets/images/super_like.png";
import eye from "../assets/images/eye.png";
import rewindInf from "../assets/images/rewind_inf.png";
import boost from "../assets/images/boost.png";
import noAds from "../assets/images/no_ads.png";
import locationFilter from "../assets/images/location_filter.png";
import aI from "../assets/images/ai.png";
import goldBadge from "../assets/images/gold_badge.png";
import platinumBadge from "../assets/images/platinum_badge.png";
import lifeTimeBadge from "../assets/images/life_time_badge.png";

const Package = () => {

    const [data, setData] = useState([]);
    const [pkgData, setPkgData] = useState([]);
    const [skuData, setSkuData] = useState([]);
    const [featData, setFeatData] = useState(null);
    const [editId, setEditId] = useState(0);
    const [delId, setDelId] = useState(0);
    const [filterId, setFilterId] = useState(0);
    
    const [modalDelShow, setModalDelShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalFeatShow, setModalFeatShow] = useState(false);
    const [editData, setEditData] = useState(null);
    
    useEffect(() => {
        fetchPlans();
        if(filterId === 0) {
            fetchPackageSkus();
        }
    }, [filterId])

    const fetchPlans = async () => {
        let url = filterId !== 0 ? `plans?plantyp=${filterId}` : `plans`;
        getApi(url, true)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                if (err?.response?.status === 403) {
                    console.log("🚀 ~ fetchPlans ~ err:", err)
                }
            });
    }

    const fetchPackageSkus = async () => {
        let url = `dropdown-package-skus`;
        getApi(url, true)
            .then(({data}) => {
                setPkgData(data.packages)      
                setSkuData(data.skus)      
            })
            .catch((err) => {
                if (err?.response?.status === 403) {
                    console.log("🚀 ~ fetchPackageSkus ~ err:", err)
                }
            });
    }

    const handlePlanModel = async (id) => {
        setEditId(id)
        let url = `plans?plan=${id}`;
        await getApi(url, true)
            .then(({data, status}) => {
                if(status === 200) {
                    setModalShow(true);
                    setEditData(data);
                    setInitialValues(data);
                }
            })
            .catch((err) => {
                if (err?.response?.status === 403) {
                    console.log("🚀 ~ handlePlanModel ~ err:", err)
                }
            });
    }

    const [initialValues, setInitialValues] = useState({
        title: "",
        sub_title: "",
        plan_tag: "",
        is_topup: 0,
        package_id: 0,
        price: "",
        discount: null,
        how_many_purchase: null,
        icons: null,
        time_duration: null,
        hasWeekly: 0,
        hasMonthly: 0,
        skus_id: 0
    })

    const validPkgValues = pkgData.map(({ id }) => id);
    const validSkuValues = skuData.map(({ id }) => id);

    const validationSchema = Yup.object({
        title: Yup.string().required("Title is required.").matches(/^[a-zA-Z0-9 ₹$&%#@-]+$/, "alpha, numerics and ₹$&%#@ symbols are allowed."),
        sub_title: Yup.string().nullable().matches(/^[a-zA-Z0-9 ₹$&/%#@-]+$/, "alpha, numerics and ₹$&%#@ symbols are allowed."),
        plan_tag: Yup.string().nullable().matches(/^[a-zA-Z0-9 ₹$&%#@-]+$/, "alpha, numerics and ₹$&%#@ symbols are allowed."),
        price: Yup.string().required("Price is required.").matches(/^[0-9 ₹$]+$/, "0-9 and ₹, $ symbols are allowed."),
        time_duration: Yup.string().nullable().matches(/^[0-9]+$/, "0-9 and ₹, $ symbols are allowed."),
        how_many_purchase: Yup.string().nullable().matches(/^[0-9]+$/, "0-9 are allowed."),
        discount: Yup.string().nullable().matches(/^[0-9]+$/, "0-9 are allowed."),
        package_id: Yup.number().required("Package is required.").oneOf(validPkgValues, "Select your plan package."),
        skus_id: Yup.number().required("SKU is required.").oneOf(validSkuValues, "Select your plan SKU.")
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            values.is_topup = values.is_topup ? 1 : 0;
            let url = editId ? `update-plan/${editId}` : `add-plan`;
            await postApi(url, values, true, true).then((res) => {
                successNotify(res.message);
                fetchPlans();
                handleCloseModel();
            });
        },
    });
    
    const handleCloseModel = async () => {
        setModalShow(false);
        setInitialValues({
            title: "",
            sub_title: "",
            plan_tag: "",
            is_topup: 0,
            package_id: 0,
            price: "",
            discount: null,
            how_many_purchase: null,
            icons: null,
            time_duration: null,
            hasWeekly: 0,
            hasMonthly: 0,
            skus_id: 0
        })
    }

    const handleDeletePlan = async () => {
        await getApi(`delete-plan/${delId}`, true).then((res) => {
            successNotify(res.message);
            setModalDelShow(false);
            fetchPlans();
        })
    }

    const handlePackageFeat = async (id) => {
        let url = `package-feature?pkg_id=${id}`;
        await getApi(url, true).then((res) => {
            setFeatData(res.data)
            setModalFeatShow(true)
        })
    }
      
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <button class="mybtn float-end" onClick={() => setModalShow(true)}>add Plan</button>
                    <select class="my-select float-end w-auto me-4 mb-0" onChange={(e) => setFilterId(e.target.value)}>
                        <option>Select Category</option>
                        <option value={0}>All</option>
                        <option value={1}>Package</option>
                        <option value={2}>Top Up</option>
                    </select>
                </div>
                {data?.map((pkgVal, pkgKey) => {
                    if(pkgVal.title === "Gold" && pkgVal.id === 1) {
                        return <>
                            <div className="col-12">
                                <p className="package-plans mt-5">
                                    <HeadingSvg/>
                                    <span className="text-uppercase">{pkgVal.title}</span>
                                    <button className="feat-btn" style={{backgroundColor: "#F8D96B"}} onClick={() => handlePackageFeat(pkgVal.id)}>Features</button>
                                </p>
                            </div>
                            {pkgVal?.plans?.sort((a, b) => b.price - a.price).map((planVal, planKey) => {
                                return <div className="col-lg-3">
                                    <div className="plans-box" style={{backgroundColor: "#F8D96B"}}>
                                        <div className="plans-duration">{planVal.title}</div>
                                        <div className="plans-box-description">
                                            <p className="plans-price">₹{planVal.price}</p>
                                            <p className="plans-dived-price">{planVal.sub_title}</p>
                                        </div>
                                        <div className="plans-tags" style={{backgroundColor: "#F8D96D"}}>{planVal.plan_tag}</div>
                                        <div className="plans-action-btn plans-edit" onClick={() => handlePlanModel(planVal.id)}><EditPlanSvg/></div>
                                        <div className="plans-action-btn plans-delete" onClick={() => {setDelId(planVal.id); setModalDelShow(true)}}><DeleteSvg/></div>
                                    </div>
                                </div>})
                            }
                        </>
                    } else if(pkgVal.title === "Platinum" && pkgVal.id === 2) {
                        return <>
                            <div className="col-12">
                                <p className="package-plans mt-5">
                                    <HeadingSvg/>
                                    <span className="text-uppercase">{pkgVal.title}</span>
                                    <button className="feat-btn" style={{backgroundColor: "#D9D9D9"}} onClick={() => handlePackageFeat(pkgVal.id)}>Features</button>
                                </p>
                            </div>
                            {pkgVal?.plans?.sort((a, b) => b.price - a.price).map((planVal, planKey) => {
                                return <div className="col-lg-3">
                                    <div className="plans-box" style={{backgroundColor: "#D9D9D9"}}>
                                        <div className="plans-duration">{planVal.title}</div>
                                        <div className="plans-box-description">
                                            <p className="plans-price">₹{planVal.price}</p>
                                            <p className="plans-dived-price">{planVal.sub_title}</p>
                                        </div>
                                        <div className="plans-tags" style={{backgroundColor: "#D9D9D9"}}>{planVal.plan_tag}</div>
                                        <div className="plans-action-btn plans-edit" onClick={() => handlePlanModel(planVal.id)}><EditPlanSvg/></div>
                                        <div className="plans-action-btn plans-delete" onClick={() => {setDelId(planVal.id); setModalDelShow(true)}}><DeleteSvg/></div>
                                    </div>
                                </div>})
                            }
                        </>
                    } else if(pkgVal.title === "Lifetime" && pkgVal.id === 3)  {
                        return <>
                            <div className="col-12">
                                <p className="package-plans mt-5">
                                    <HeadingSvg/>
                                    <span className="text-uppercase">{pkgVal.title}</span>
                                    <button className="feat-btn" style={{backgroundColor: "#EA2366"}} onClick={() => handlePackageFeat(pkgVal.id)}>Features</button>
                                </p>
                            </div>
                            {pkgVal?.plans?.sort((a, b) => b.price - a.price).map((planVal, planKey) => {
                                return <div className="col-lg-3">
                                    <div className="plans-box" style={{backgroundColor: "#EA2366"}}>
                                        <div className="plans-duration">{pkgVal.title}</div>
                                        <div className="plans-box-description">
                                            <p className="plans-price pt-2">₹{planVal.price}</p>
                                        </div>
                                        <div className="plans-action-btn plans-edit" onClick={() => handlePlanModel(planVal.id)}><EditPlanSvg/></div>
                                        <div className="plans-action-btn plans-delete" onClick={() => {setDelId(planVal.id); setModalDelShow(true)}}><DeleteSvg/></div>
                                    </div>
                                </div>})
                            }
                        </>
                    } else if(pkgVal.topup_type === 1 && pkgVal.id === 1)  {
                        return <>
                            <div className="col-12">
                                <p className="package-plans mt-5">
                                    <HeadingSvg/>
                                    <span className="text-uppercase">{pkgVal.title}</span>
                                </p>
                            </div>
                            {pkgVal?.topupplans?.sort((a, b) => b.price - a.price).map((planVal, planKey) => {
                                return <div className="col-lg-3">
                                    <div className="topupplans-box" style={{backgroundColor: "#0A0406"}}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="topupplans-tags" style={{color: "#FB2A75"}}>{planVal.discount !== null ? planVal.plan_tag : 'Normal'}</div>
                                            <div className="topupplans-discount" style={{backgroundColor: "#FB2A75"}}>Save {planVal.discount !== null ? planVal.discount : 0}%</div>
                                        </div>
                                        <div className="topupplans-icon topupplans-superlike">
                                            <SuperLikeSvg/>
                                        </div>
                                        <div className="topupplans-box-description">
                                            <p className="topupplans-qty">{planVal.title}</p>
                                            <p className="topupplans-price">₹{planVal.price}</p>
                                        </div>
                                        <p className="topupplans-edit" onClick={() => handlePlanModel(planVal.id)}><EditPlanSvg/></p>
                                        <p className="topupplans-delete" onClick={() => {setDelId(planVal.id); setModalDelShow(true)}}><DeleteSvg/></p>
                                    </div>
                                </div>})
                            }
                        </>
                    } else if(pkgVal.topup_type === 2 && pkgVal.id === 2)  {
                        return <>
                            <div className="col-12">
                                <p className="package-plans mt-5">
                                    <HeadingSvg/>
                                    <span className="text-uppercase">{pkgVal.title}</span>
                                </p>
                            </div>
                            {pkgVal?.topupplans?.sort((a, b) => b.price - a.price).map((planVal, planKey) => {
                                return <div className="col-lg-3">
                                    <div className="topupplans-box" style={{backgroundColor: "#0A0406"}}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="topupplans-tags" style={{color: "#F9EF63"}}>{planVal.discount !== null ? planVal.plan_tag : 'Normal'}</div>
                                            <div className="topupplans-discount" style={{backgroundColor: "#F9EF63"}}>Save {planVal.discount !== null ? planVal.discount : 0}%</div>
                                        </div>
                                        <div className="topupplans-icon topupplans-boost">
                                            <BoostSvg/>
                                        </div>
                                        <div className="topupplans-box-description">
                                            <p className="topupplans-qty">{planVal.title}</p>
                                            <p className="topupplans-price">₹{planVal.price}</p>
                                        </div>
                                        <p className="topupplans-edit" onClick={() => handlePlanModel(planVal.id)}><EditPlanSvg/></p>
                                        <p className="topupplans-delete" onClick={() => {setDelId(planVal.id); setModalDelShow(true)}}><DeleteSvg/></p>
                                    </div>
                                </div>})
                            }
                        </>
                    } else if(pkgVal.topup_type === 3 && pkgVal.id === 3)  {
                        return <>
                            <div className="col-12">
                                <p className="package-plans mt-5">
                                    <HeadingSvg/>
                                    <span className="text-uppercase">{pkgVal.title}</span>
                                </p>
                            </div>
                            {pkgVal?.topupplans?.sort((a, b) => b.price - a.price).map((planVal, planKey) => {
                                return <div className="col-lg-3">
                                    <div className="topupplans-box" style={{backgroundColor: "#0A0406"}}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="topupplans-tags" style={{color: "#45D6F6"}}>{planVal.discount !== null ? planVal.plan_tag : 'Normal'}</div>
                                            <div className="topupplans-discount" style={{backgroundColor: "#45D6F6"}}>Save {planVal.discount !== null ? planVal.discount : 0}%</div>
                                        </div>
                                        <div className="topupplans-icon topupplans-diamond">
                                            <DiamondSvg/>
                                        </div>
                                        <div className="topupplans-box-description">
                                            <p className="topupplans-qty">{planVal.title}</p>
                                            <p className="topupplans-price">₹{planVal.price}</p>
                                        </div>
                                        <p className="topupplans-edit" onClick={() => handlePlanModel(planVal.id)}><EditPlanSvg/></p>
                                        <p className="topupplans-delete" onClick={() => {setDelId(planVal.id); setModalDelShow(true)}}><DeleteSvg/></p>
                                    </div>
                                </div>})
                            }
                        </>
                    }
                })}
            </div>

            {/* Plan add & Update model */}
            <Modal show={modalShow} onHide={handleCloseModel}>
                <Modal.Header className="d-flex justify-content-between">
                    <Modal.Title>{editData ? 'Edit Plan' : 'Add Plan'}</Modal.Title>
                    <Button className="showWrap rmvpopbtn" onClick={handleCloseModel}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="currentcolor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                        </svg>
                    </Button>
                </Modal.Header>
                <form method="post" onSubmit={formik.handleSubmit}>  
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <Form.Check inline type={'checkbox'} id={`topup`} onChange={(e) => formik.setFieldValue("is_topup", !formik.values.is_topup)} checked={formik.values.is_topup} />
                                <label htmlFor="topup">It's a TopUp</label>
                            </div>
                            <div className="col-md-4 text-center">
                                <Form.Check inline name="hasWeekly-plan" type={'radio'} id={`weekly-plan`} onChange={(e) => formik.setFieldValue("hasWeekly",1)} checked={formik.values.hasWeekly === 1} />
                                <label htmlFor="weekly-plan">It's a Weekly plan</label>
                            </div>
                            <div className="col-md-4 text-center">
                                <Form.Check inline name="hasWeekly-plan" type={'radio'} id={`monthly-plan`} onChange={(e) => formik.setFieldValue("hasMonthly", 1)} checked={formik.values.hasMonthly === 1} />
                                <label htmlFor="monthly-plan">It's a Monthly plan</label>
                            </div>
                            <div className="col-md-6">
                                <div className="mt-4">
                                    <label className="popup__label">Title</label>
                                    <div className="form-input">
                                        <input type="text" placeholder="Enter title" name="title" className="popup__input" onChange={formik.handleChange} value={formik.values.title}/>
                                    </div>
                                </div>
                                {formik.touched.title && formik.errors.title ? (
                                    <p className="form-error">{formik.errors.title}</p>
                                ) : null}
                            </div>
                            <div className="col-md-6">
                                <div className="mt-4">
                                    <label className="popup__label">Sub title</label>
                                    <div className="form-input">
                                        <input type="text" placeholder="Enter sub title" name="sub_title" className="popup__input" onChange={formik.handleChange} value={formik.values.sub_title}/>
                                    </div>
                                </div>
                                {formik.touched.sub_title && formik.errors.sub_title ? (
                                    <p className="form-error">{formik.errors.sub_title}</p>
                                ) : null}
                            </div>                            
                            <div className="col-md-6">
                                <label className="popup__label">Select package</label>
                                <select name="package_id" onChange={formik.handleChange} className="my-select" value={formik.values.package_id}>
                                    <option value="">Select package</option>
                                    {
                                        pkgData?.map((pkgVal, pkgKey) => {
                                            return <option value={pkgVal.id}>{pkgVal.title}</option>
                                        })
                                    }
                                </select>    
                                {formik.touched.package_id && formik.errors.package_id ? (
                                    <p className="form-error">{formik.errors.package_id}</p>
                                ) : null}
                            </div>                         
                            <div className="col-md-6">
                                <label className="popup__label">Skus</label>
                                <select name="skus_id" onChange={formik.handleChange} className="my-select" value={formik.values.skus_id}>
                                    <option value="">Select skus</option>
                                    {
                                        skuData?.map((skuVal, skuKey) => {
                                            return <option value={skuVal.id}>{skuVal.sku}</option>
                                        })
                                    }
                                </select>    
                                {formik.touched.skus_id && formik.errors.skus_id ? (
                                    <p className="form-error">{formik.errors.skus_id}</p>
                                ) : null}
                            </div>                         
                            <div className="col-md-6">
                                <div>
                                    <label className="popup__label">Price</label>
                                    <div className="form-input">
                                        <input type="text" placeholder="Enter price" name="price" className="popup__input" onChange={formik.handleChange} value={formik.values.price}/>
                                    </div>
                                </div>
                                {formik.touched.price && formik.errors.price ? (
                                    <p className="form-error">{formik.errors.price}</p>
                                ) : null}
                            </div>                            
                            <div className="col-md-6">
                                <div>
                                    <label className="popup__label">Discount (%)</label>
                                    <div className="form-input">
                                        <input type="text" placeholder="Enter discount" name="discount" className="popup__input" onChange={formik.handleChange} value={formik.values.discount}/>
                                    </div>
                                </div>
                                {formik.touched.discount && formik.errors.discount ? (
                                    <p className="form-error">{formik.errors.discount}</p>
                                ) : null}
                            </div>   
                            <div className="col-md-4">
                                <div>
                                    <label className="popup__label">Plan Tag</label>
                                    <div className="form-input">
                                        <input type="text" placeholder="Enter Plan tag" name="plan_tag" className="popup__input" onChange={formik.handleChange} value={formik.values.plan_tag}/>
                                    </div>
                                </div>
                                {formik.touched.plan_tag && formik.errors.plan_tag ? (
                                    <p className="form-error">{formik.errors.plan_tag}</p>
                                ) : null}
                            </div>                            
                            <div className="col-md-4">
                                <div>
                                    <label className="popup__label">Valid Into Month/Week</label>
                                    <div className="form-input">
                                        <input type="text" placeholder="Enter Time Duration" name="time_duration" className="popup__input" onChange={formik.handleChange} value={formik.values.time_duration}/>
                                    </div>
                                </div>
                                {formik.touched.time_duration && formik.errors.time_duration ? (
                                    <p className="form-error">{formik.errors.time_duration}</p>
                                ) : null}
                            </div>   
                            <div className="col-md-4">
                                <div>
                                    <label className="popup__label">How many purchase?</label>
                                    <div className="form-input">
                                        <input type="text" placeholder="How many purchase?" name="how_many_purchase" className="popup__input" onChange={formik.handleChange} value={formik.values.how_many_purchase}/>
                                    </div>
                                </div>
                                {formik.touched.how_many_purchase && formik.errors.how_many_purchase ? (
                                    <p className="form-error">{formik.errors.how_many_purchase}</p>
                                ) : null}
                            </div>   
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="mybtn">Submit</button>
                        <button type="button" className="mybtn" onClick={handleCloseModel}>Cancel</button>
                    </Modal.Footer>
                </form>
            </Modal>
            {/* --- Plan add & Update model --- */}

            {/* Delete conformation model */}
            <Modal show={modalDelShow} onHide={() => setModalDelShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="mybtn black" onClick={() => setModalDelShow(false)}>Cancel</Button>
                    <Button variant="primary" className="mybtn" onClick={handleDeletePlan}>Delete</Button>
                </Modal.Footer>
            </Modal>
            {/* --- Delete conformation model --- */}

            {/* Pkg Features model */}
            <Modal show={modalFeatShow} onHide={() => setModalFeatShow(false)} animation={true} dialogClassName="modal-30w" className="pkg-feat-modal">
                <Modal.Body>
                    <div className="feat-title"><span className="text-uppercase" style={featData?.id === 1 ? {color: '#FFC300'} : featData?.id === 2 ? {color: '#D9D9D9'} : {color: '#EA2366'} }>{featData?.title}</span></div>
                    {
                        featData?.includes?.map((featVal, featKey) => {
                            const category = Object.keys(featVal)[0];
                            const features = featVal[category];

                            return (
                                <div className="feat-boxs" key={featKey}>
                                    <p className="feat-boxs-title">{category}</p>
                                    {features.map((feature, featureKey) => (
                                        <div className="features">
                                            <div className="feat-img-title">
                                                <div className="features-img">
                                                    {feature?.icon === 'likes' ? 
                                                        <img src={likeInf} alt="" /> : 
                                                    feature?.icon === 'super_likes' ? 
                                                        <img src={superLike} alt="" /> : 
                                                    feature?.icon === 'see' ? 
                                                        <img src={eye} alt="" /> : 
                                                    feature?.icon === 'rewinds' ? 
                                                        <img src={rewindInf} alt="" /> :
                                                    feature?.icon === 'boosts' ? 
                                                        <img src={boost} alt="" /> : 
                                                    feature?.icon === 'badge' ? 
                                                        (featData?.id === 1 ? 
                                                            <img src={goldBadge} alt="" /> : 
                                                        featData?.id === 2 ? 
                                                            <img src={platinumBadge} alt="" /> : 
                                                        <img src={lifeTimeBadge} alt="" />) : 
                                                    feature?.icon === 'no_ads' ? 
                                                        <img src={noAds} alt="" /> : 
                                                    feature?.icon === 'location_filter' ? 
                                                        <img src={locationFilter} alt="" /> : 
                                                    feature?.icon === 'ai' ? 
                                                        <img src={aI} alt="" /> : <img src={eye} alt="" />}
                                                </div>
                                                <div className="features-title">
                                                    <span className="first-title">{feature?.title}</span><br/>
                                                    {feature?.subtitle !== null ? <span className="second-title">{feature?.subtitle}</span> : ''}
                                                </div>
                                            </div>
                                            <div className="feat-right-lock-icon">
                                                {parseInt(feature?.status) === 1 ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.75 12L10.58 14.83L16.25 9.17" stroke={featData?.id === 1 ? '#FFC300' : featData?.id === 2 ? '#D9D9D9' : '#EA2366' } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg> :
                                                <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.5 9.16665V7.33331C5.5 4.29915 6.41667 1.83331 11 1.83331C15.5833 1.83331 16.5 4.29915 16.5 7.33331V9.16665" stroke={featData?.id === 1 ? '#FFC300' : featData?.id === 2 ? '#D9D9D9' : '#EA2366' } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M11 16.9583C12.2656 16.9583 13.2916 15.9323 13.2916 14.6667C13.2916 13.401 12.2656 12.375 11 12.375C9.73433 12.375 8.70831 13.401 8.70831 14.6667C8.70831 15.9323 9.73433 16.9583 11 16.9583Z" stroke={featData?.id === 1 ? '#FFC300' : featData?.id === 2 ? '#D9D9D9' : '#EA2366' } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M15.5833 20.1667H6.41665C2.74998 20.1667 1.83331 19.25 1.83331 15.5834V13.75C1.83331 10.0834 2.74998 9.16669 6.41665 9.16669H15.5833C19.25 9.16669 20.1666 10.0834 20.1666 13.75V15.5834C20.1666 19.25 19.25 20.1667 15.5833 20.1667Z" stroke={featData?.id === 1 ? '#FFC300' : featData?.id === 2 ? '#D9D9D9' : '#EA2366' } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        })
                    }
                </Modal.Body>   
            </Modal>
            {/* --- Pkg Features model --- */}
        </>
    );
}

export default Package;
