import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";

export default function Orientations() {
  const title = ["id", "name", "status"];

  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("Basic name Required"),
    }),
  };

  const initialValues = {
    name: "",
    status: 0,
  };

  return (
    <div>
      <TableDisplay
        category="Orientations"
        tableurl="orientations"
        title={title}
        deleteurl="delete-orientations"
        deleteallurl="all-delete-orientations"
        addurl="add-orientations"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-orientations"
        updatteurl="edit-orientations"
        serachCategory="orientations"
        statusurl="status-orientations"
        axiosHeader={false}
      />
    </div>
  );
}
