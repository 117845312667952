import { configureStore } from '@reduxjs/toolkit';
import auth from './reducer/auth';

const store = configureStore({
    reducer: {
        authentication: auth,
    },
});

export const RootState = store.getState;
export const AppDispatch = store.dispatch;
export default store;