import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
import { getApi } from "../Helper/api";
export default function Interests() {
  const title = ["id", "name", "icons", "status"];

  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "icons",
        label: "Icon",
        type: "file",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("Basic name Required"),
      icons: Yup.mixed().required("Please upload an image"),
    }),
  };

  const initialValues = {
    name: "",
    icons: "",
    status: 0,
  };

  return (
    <div>
      <TableDisplay
        category="Interests"
        tableurl="interests"
        title={title}
        deleteurl="delete-interests"
        deleteallurl="all-delete-interests"
        addurl="add-interests"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-interests"
        updatteurl="edit-interests"
        serachCategory="interests"
        statusurl="status-interests"
        axiosHeader={true}
      />
    </div>
  );
}
