import React, { useEffect, useState } from "react";
import { getApi } from "../Helper/api";
import { useParams } from "react-router-dom";
import VerifyIcon from "../assets/images/verify.svg";
import unverifyedIcon from "../assets/images/unverifyed.svg";

import { Image } from 'antd';
import 'antd/dist/antd.css';

export default function UserDetail() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [imageArray, setImageArray] = useState([]);
  const [toggler, setToggler] = useState(false);

  const fetchImage = async () => {
    try {
      const { status, data } = await getApi(`get-images/${id}`, true);
      if (status === 200) {
        let newData = [...data];
        let newArray = [];
        await newData.map((dataVal, dataKey) => {
          let img = `${process.env.REACT_APP_UPLOADS_URL}users/${dataVal.image}`;
          newArray.push(img);
        })
        setImageArray(newArray);
      }
    } catch (err) {
      console.error("Error fetching images:", err);
    }
  };

  const fetchData = async () => {
    try {
      const { status, data } = await getApi(`get-edit-user/${id}`, true);
      if (status === 200) setData(data);
      console.log("🚀 ~ fetchData ~ data:", data)
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchImage();
  }, [id]);

  const formatDate = (isoString) => {
    try {
      const date = new Date(isoString);
      if (isNaN(date)) throw new Error("Invalid date");
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true
      }).format(date);
    } catch {
      return "";
    }
  };

  return (
    <div>
      <div className="Image-section d-flex justify-content-center gap-4">
        <div className="imageWrap user">
          <Image.PreviewGroup>
            {imageArray.map((file, index) => {
              return (<Image src={`${file}`}/>)
            })}
          </Image.PreviewGroup>
        </div>
      </div>
      <div className="d-flex gap-4 justify-content-between mt-4 mb-4">
        <div className="user-info col-md-5 d-flex">
          <div className="col-md-8">
            {data.fullname && (
              <h4><b>Name :</b> {data.fullname}</h4>
            )}
            {data.email && (
              <p><b>Email :</b> {data.email}</p>
            )}
            {data.mobile_num && (
              <p><b>Mobile Number :</b>{" "}{data.country_code + " " + data.mobile_num}</p>
            )}
            {data.about_me && (
              <p><b>About Me :</b> {data.about_me}</p>
            )}
          </div>
          <div className="col-md-4 text-end">
            {data.birth_date && (
              <p><b>Birth Date :</b> {data.birth_date}</p>
            )}
            {data.age && (
              <p><b>Age :</b> {data.age + " Years"}</p>
            )}
            {data.gender && (
              <p><b>Gender :</b> {data.gender}</p>
            )}
          </div>
        </div>
        <div className="user-info col-md-5 d-flex text-end">
          <div className="col-md-12">
            {data.createdAt && (
              <h5>Create Date: {formatDate(data.createdAt)}</h5>
            )}
            {data.updatedAt && (
              <h5>Update Date: {formatDate(data.updatedAt)}</h5>
            )}
            {data.deleted_at && (
              <h5>Delete Date: {formatDate(data.deleted_at)}</h5>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 mb-4">
        <div className="user-info col-md-5 d-flex">
          <div className="col-md-8">
            {data.seeing_interest && (
              <p><b>Interested In :</b> {data.seeing_interest}</p>
            )}
            {data.orientation_id && (
              <p><b>Orientation :</b> {data.orientation_id}</p>
            )}
            {data.looking_for && (
              <p><b>Looking For :</b> {data.looking_for}</p>
            )}
          </div>
          <div className="col-md-4 text-end">
            {data.login_type && (
              <p><b>Login Type :</b>{" "} {data.login_type === "1" ? "Google" : "Mobile"}</p>
            )}
            {data.is_verified_type && (
              <p><b>Verify Type :</b>{" "}{data.is_verified_type === "1" ? "Google" : "Mobile"}</p>
            )}
            {data.is_verified !== undefined && (
              <p>
                <b>Verified :</b>
                <img src={data.is_verified ? VerifyIcon : unverifyedIcon} alt={data.is_verified ? "verify icon" : "unverifyed icon"}/>
              </p>
            )}
          </div>
        </div>
        <div className="user-info col-md-5">
          {data.my_interests?.length > 0 && (
            <p>
              <b>Interests: </b>
              {data.my_interests.map((item, index) => 
                index === data.my_interests.length - 1 ? item.label : item.label + ", "
              )}
            </p>
          )}
          {data.basic_id?.length > 0 && (
            <p>
              <b>Basics: </b>
              {data.basic_id.map((item, index) =>
                index === data.basic_id.length - 1 ? item.label : item.label + ", "
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
