import React from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
export default function Skus() {
  const title = ["id", "sku", "status"];
  const formConfig = {
    fields: [
      {
        name: "sku",
        label: "SKU",
        type: "text",
        placeholder: "enter Sku",
      },
      {
        name: "is_top_up",
        label: "Is Top Up",
        type: "switch",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      sku: Yup.string().required("Sku Required"),
    }),
  };

  const initialValues = {
    sku: "",
    is_top_up: 0,
    status: 0,
  };
  return (
    <div>
      <TableDisplay
        category="Sku"
        tableurl="sku"
        title={title}
        deleteurl="delete-sku"
        deleteallurl="all-delete-sku"
        addurl="add-sku"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-sku"
        updatteurl="edit-sku"
        serachCategory="sku"
        statusurl="status-sku"
        axiosHeader={false}
      />
    </div>
  );
}
