import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";
import { getApi } from "../Helper/api";
export default function Topups() {
  const title = ["id", "title", "topup_type", "description", "status"];
  const [topup, setTopup] = useState([]);
  const formConfig = {
    fields: [
      {
        name: "title",
        label: "Title",
        type: "text",
        placeholder: "enter title",
      },
      {
        name: "topup_type",
        label: "Topup Type",
        type: "select",
        options: topup && topup,
      },
      {
        name: "description",
        label: "Description",
        type: "text",
        placeholder: "enter description",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      title: Yup.string().required("title Required"),
      description: Yup.string().required("description Required"),
      topup_type: Yup.string().required("title Required"),
    }),
  };

  const initialValues = {
    title: "",
    topup_type: "",
    description: "",
    status: 0,
  };
  const fetchData = async () => {
    await getApi("topupcategory", true).then((res) => {
      setTopup(res.data);
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <TableDisplay
        category="Top Up"
        tableurl="topup"
        title={title}
        deleteurl="delete-topup"
        deleteallurl="all-delete-topup"
        addurl="add-topup"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-topup"
        updatteurl="edit-topup"
        serachCategory="topup"
        statusurl="status-topup"
        axiosHeader={false}
      />
    </div>
  );
}
