import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Pagination from "./Pagination";
import { getApi } from "../Helper/api";
import { convertUTCtoDate } from "../Helper/helper";
import Highlighter from "react-highlight-words";

export default function Users() {

    const [userData, setUserData] = useState([]);
    const [isNumberType, setIsNumberType] = useState(-1);
    const [fakeUser, setFakeUser] = useState("");
    const [genderType, setgenderType] = useState("");

    /*************Pagination */
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [count, setCount] = useState(0);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = async () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const highlightText = (text, search) => (
        <Highlighter
            highlightClassName="YourHighlightClass"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={text}
        />
    );

    /***************** */
    useEffect(() => {
        getAll();
    }, [currentPage, search, fakeUser, genderType]);

    let getAll = async () => {
        let url = `get-splash-user?page=${currentPage}`;
        if (search !== "" || search !== null || search !== undefined)
        url += `&search=${search}`;
        await getApi(url, true)
            .then((res) => {
                if (res.status === 200) {
                    setUserData(res.data);
                    setTotalPages(res.totalPages);
                    setCount(res.count);
                }
            })
            .catch((err) => {
                console.log("Fetching data errror", err);
            });
    };

    useEffect(() => {
        const handlePageClick = async (event) => {
            if (event?.target?.className === "tbl-menu") {
                return;
            } else {
                setIsNumberType(0);
            }
        };

        // Add click event listener to the document
        document.addEventListener("click", handlePageClick);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener("click", handlePageClick);
        };
    }, []);

    return (
        <>
        <div className="d-flex justify-content-between">
            <div className="title d-flex justify-content-between align-middle align-items-center">
                <h4 className="d-flex justify-content-between marginright align-items-center">Splash User</h4>
                {count === 1 ? (
                    <h6 className="m-0">( {count} Record )</h6>
                ) : count > 0 ? (
                    <h6>( {count} Records )</h6>
                ) : null}
            </div>
            <div className="w-75 d-flex justify-content-end align-items-center">
                <div className="search marginright w-50">
                    <input type="text" className="search__input marginright popup__input" onChange={(e) => {setSearch(e.target.value); setCurrentPage(1)}} placeholder={`Search (Country, State, City, Isp, Org, Package, Users By)`}/>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path 
                            fill="black"
                            d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                        />
                    </svg>
                </div>
            </div>
        </div>
        
        <Table className="data_table" responsive="sm">
            <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">device id</th>
                    <th className="text-center">country</th>
                    <th className="text-center">state</th>
                    <th className="text-center">city</th>
                    <th className="text-center">isp</th>
                    <th className="text-center">org</th>
                    <th className="text-center">packagename</th>
                    <th className="text-center">splash users by</th>
                    <th className="text-center">created At</th>
                </tr>
            </thead>
            <tbody>
                {userData.length !== 0 ? (
                    userData?.map((val, key) => {
                        return (
                            <tr className="text-center" key={key}>
                                <td>{val.id}</td>
                                <td>{highlightText(val.device_id, search)}</td>                    
                                <td>{highlightText(val.country, search)}</td>                    
                                <td>{highlightText(val.state, search)}</td>                    
                                <td>{highlightText(val.city, search)}</td>                    
                                <td>{highlightText(val.isp, search)}</td>                    
                                <td>{highlightText(val.org, search)}</td>                    
                                <td>{highlightText(val.packagename, search)}</td>                    
                                <td>{highlightText(val.splash_users_by, search)}</td>                    
                                <td>{highlightText(convertUTCtoDate(val.createdAt), search)}</td>                    
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={12} className="text-center">No data available</td>
                    </tr>
                )}
            </tbody>
        </Table>

        {totalPages > 1 ? (
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrev={handlePrevPage}
                handlePageChange={handlePageChange}
            />
        ) : null}
        </>
    );
}
