import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { loginUser } from "../Redux/reducer/auth";

// Helper
import { getApi } from "../Helper/api";

// PNGs
import logoImage from "../assets/images/logo.png";

// SVGs
import { ReactComponent as UserSvg } from "../assets/images/user.svg";
import { ReactComponent as CountrySvg } from "../assets/images/location.svg";
import { ReactComponent as CategoryBasicsSvg } from "../assets/images/catagory basic.svg";
import { ReactComponent as BasicsSvg } from "../assets/images/Basics.svg";
import { ReactComponent as InterestsSvg } from "../assets/images/interests.svg";
import { ReactComponent as LookingForSvg } from "../assets/images/looking for.svg";
import { ReactComponent as OrientationsSvg } from "../assets/images/orientation.svg";
import { ReactComponent as SkuSvg } from "../assets/images/sku.svg";
import { ReactComponent as AdminSettingSvg } from "../assets/images/settings.svg";
import { ReactComponent as TopupSvg } from "../assets/images/top up.svg";
import { ReactComponent as TopupCategorySvg } from "../assets/images/top up catagory.svg";
import { ReactComponent as PackageSvg } from "../assets/images/packages.svg";
import { ReactComponent as PlanSvg } from "../assets/images/plans.svg";

export default function Sidebar() {
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    await getApi("get-admin", true).then(({ data }) => {
      dispatch(loginUser(data));
    });
  };

  return (
    <>
      <div className="navbarclass">
        <label htmlFor="menu" className="icon">
          <div className="menu d-flex justify-content-between">
            <div className="d-flex">
              <div className="logo-wrap">
                <img src={logoImage} alt="logo" />
              </div>
              <h3 className="logo-text">Belive</h3>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
              <path d="M22 12H3" stroke="#11142d"></path>
              <g stroke="#808191">
                <path d="M22 4H13"></path>
                <path opacity=".301" d="M22 20H13"></path>
              </g>
              <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
            </svg>
          </div>
        </label>

        <nav>
          <ul className="sidemenu">
            <li className={path === "/splash-user" || path.startsWith("/splash-user/") ? "active" : ""}>
              <NavLink aria-current="page" to="/splash-user">
                <div className="svgwarp">
                  <UserSvg />
                </div>
                Splash User
              </NavLink>
            </li>
            <li className={path === "/user" || path === "/add-user" || path.startsWith("/edit-user/") || path.startsWith("/user/") ? "active" : ""}>
              <NavLink aria-current="page" to="/user">
                <div className="svgwarp">
                  <UserSvg />
                </div>
                Users
              </NavLink>
            </li>
            <li className={`${path}` === "/country" ? "active" : ""}>
              <NavLink aria-current="page" to="/country">
                <div className="svgwarp">
                  <CountrySvg />
                </div>
                Country
              </NavLink>
            </li>
            <li className={`${path}` === "/categorybasics" ? "active" : ""}>
              <NavLink aria-current="page" to="/categorybasics">
                <div className="svgwarp">
                  <CategoryBasicsSvg />
                </div>
                Category Basic
              </NavLink>
            </li>
            <li className={`${path}` === "/basics" ? "active" : ""}>
              <NavLink aria-current="page" to="/basics">
                <div className="svgwarp">
                  <BasicsSvg />
                </div>
                Basics
              </NavLink>
            </li>
            <li className={`${path}` === "/interests" ? "active" : ""}>
              <NavLink aria-current="page" to="/interests">
                <div className="svgwarp">
                  <InterestsSvg />
                </div>
                Interests
              </NavLink>
            </li>
            <li className={`${path}` === "/lookingfor" ? "active" : ""}>
              <NavLink aria-current="page" to="/lookingfor">
                <div className="svgwarp">
                  <LookingForSvg />
                </div>
                Looking For
              </NavLink>
            </li>
            <li className={`${path}` === "/orientations" ? "active" : ""}>
              <NavLink aria-current="page" to="/orientations">
                <div className="svgwarp">
                  <OrientationsSvg />
                </div>
                Orientations
              </NavLink>
            </li>
            <li className={`${path}` === "/sku" ? "active" : ""}>
              <NavLink aria-current="page" to="/sku">
                <div className="svgwarp">
                  <SkuSvg />
                </div>
                Sku
              </NavLink>
            </li>
            <li className={`${path}` === "/adminsetting" ? "active" : ""}>
              <NavLink aria-current="page" to="/adminsetting">
                <div className="svgwarp">
                  <AdminSettingSvg />
                </div>
                Admin Setting
              </NavLink>
            </li>
            <li className={`${path}` === "/topup" ? "active" : ""}>
              <NavLink aria-current="page" to="/topup">
                <div className="svgwarp">
                  <TopupSvg />
                </div>
                Top Up
              </NavLink>
            </li>
            <li className={`${path}` === "/topupcategory" ? "active" : ""}>
              <NavLink aria-current="page" to="/topupcategory">
                <div className="svgwarp">
                  <TopupCategorySvg />
                </div>
                Topup Category
              </NavLink>
            </li>
            <li className={`${path}` === "/package" ? "active" : ""}>
              <NavLink aria-current="page" to="/package">
                <div className="svgwarp">
                  <PackageSvg />
                </div>
                Package
              </NavLink>
            </li>
            <li className={`${path}` === "/plans" ? "active" : ""}>
              <NavLink aria-current="page" to="/plans">
                <div className="svgwarp">
                  <PlanSvg />
                </div>
                Plans
              </NavLink>
            </li>
            {/*  {user && user.is_superadmin === 1 ? (
              <>
                <li className={`${path}` === "/settings" ? "active" : ""}>
                  <NavLink aria-current="page" to="/settings">
                    <div className="svgwarp">
                      <SettingSvg />
                    </div>
                    Settings
                  </NavLink>
                </li>
              </>
            ) : null}*/}
          </ul>
        </nav>
      </div>
    </>
  );
}
