import React, { useEffect, useState } from "react";
import TableDisplay from "./TableDisplay";
import * as Yup from "yup";

export default function LookingFor() {
  const title = ["id", "name", "icons", "status"];

  const formConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        placeholder: "enter name",
      },
      {
        name: "icons",
        label: "Icon",
        type: "file",
      },
      {
        name: "status",
        label: "Status",
        type: "switch",
      },
    ],
    validationSchema: Yup.object({
      name: Yup.string().required("Basic name Required"),
      icons: Yup.mixed().required("Please upload an image"),
    }),
  };

  const initialValues = {
    name: "",
    icons: "",
    status: 0,
  };

  return (
    <div>
      <TableDisplay
        category="Looking For"
        tableurl="lookingfor"
        title={title}
        deleteurl="delete-lookingfor"
        deleteallurl="all-delete-lookingfor"
        addurl="add-lookingfor"
        formConfig={formConfig}
        initialValues={initialValues}
        updateurl="get-edit-lookingfor"
        updatteurl="edit-lookingfor"
        serachCategory="lookingfor"
        statusurl="status-lookingfor"
        axiosHeader={true}
      />
    </div>
  );
}
