import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getApi, postApi } from "../Helper/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useParams } from "react-router-dom";
const animatedComponents = makeAnimated();
const SignupSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Full name is required")
    .matches(/^[a-zA-Z\s]+$/, "Full name must only contain letters and spaces"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is Required"),
  country_code: Yup.string().required("Country is required"),
  // mobile_num: Yup.string()
  //   .required("Mobile number is required")
  //   .matches(/^\d+$/, "Mobile number must contain only digits")
  //   .min(10, "Mobile number must be at least 10 digits")
  //   .max(16, "Mobile number must be at most 15 digits"),
  gender: Yup.string().required("Gender is required"),
  birth_date: Yup.date().required("Birth date is required"),
  seeing_interest: Yup.string().required("Seeing interest is required"),
  orientation_id: Yup.string().required("Orientation is required"),
  looking_for: Yup.string().required("Looking for is required"),
  about_me: Yup.string().required("About me is required"),
  latitude: Yup.string().required("latitude is required"),
  longitude: Yup.string().required("longitude is required"),
  // device_token: Yup.string().required("Device Token Required."),
  // login_type: Yup.string().required("Select Login Type."),
  // is_verified_type: Yup.string().required("Verified type is required"),
});

export default function UserForm() {
  const { id } = useParams();
  const [formdata, setFormData] = useState();
  const [country, setCountry] = useState([]);
  const [lookigforss, setLookigforsss] = useState([]);
  const [orientations, setOrientations] = useState([]);
  const [selectedBasic, setSelectedBasic] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadMultImg, setUploadMultImg] = useState();
  const [imageArray, setImageArray] = useState([]);
  const [defaultBasic, setDefaultBasic] = useState([]);
  const [defaultInterest, setDefaultInterest] = useState([]);
  const [basicOptions, setBasicOptions] = useState([]);

  const [interestOptions, setInterestOptions] = useState([]);
  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const genders = [
    { id: 1, name: "male" },
    { id: 2, name: "female" },
    { id: 3, name: "other" },
  ];
  
  const genderss = [
    { id: 1, name: "men" },
    { id: 2, name: "women" },
    { id: 3, name: "everyone" },
  ];

  const login_types = [
    { id: 1, name: "Google" },
    { id: 2, name: "Mobile" },
  ];

  const filterData = (data, xxx) => {
    const filterValues = data.map((filter) => parseInt(filter.value));
    const filteredData = xxx.filter(
      (entry) => !filterValues.includes(entry.value)
    );
    return filteredData;
  };

  const fetchData = async () => {
    try {
      await getApi("user-fetch", true).then(async (res) => {
        if (res.status === 200) {
          setCountry(res.data.country);
          setOrientations(res.data.orientations);
          setLookigforsss(res.data.lookingfor);
          let basicData = res?.data?.basics?.map((item) => ({
            value: item.id,
            label: item.basic,
          }));
          setBasicOptions(basicData);
          let interestData = res?.data?.interest?.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setInterestOptions(interestData);
          if (id !== undefined) {
            await getApi(`get-edit-user/${id}`, true).then(
              (resp) => {
                setInitialValues(resp.data);
                setDefaultBasic(resp.data.basic_id);
                setDefaultInterest(resp.data.my_interests);
                const filteredData = filterData(resp.data.basic_id, basicData);
                const filteredDataInterest = filterData(
                  resp.data.my_interests,
                  interestData
                );
                setBasicOptions(filteredData);
                setInterestOptions(filteredDataInterest);
              },
              await getApi(`get-images/${id}`, true).then((res) => {
                setImageArray(res.data);
              })
            );
          }
        }
      });
    } catch (error) {
      console.error("Error fetching countries", error);
    }
  };

  useEffect(() => {
    fetchData();
    const dtToday = new Date();
    const month = String(dtToday.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
    const day = String(dtToday.getDate()).padStart(2, "0"); // Add leading zero if needed
    const year = dtToday.getFullYear() - 18;
    const yearmin = dtToday.getFullYear() - 100;
    const formattedDate = `${year}-${month}-${day}`;
    const formattedMinDate = `${yearmin}-${month}-${day}`;
    setMaxDate(formattedDate);
    setMinDate(formattedMinDate);
  }, []);

  const handleDelete = async (file, status) => {
    let array = selectedFiles && [...selectedFiles];
    if (status === "selectedFiles" && file >= 0 && file < array.length) {
      array && array.splice(file, 1);
      setSelectedFiles(array);
    } else {
      await getApi(`admin-image-delete?id=${id}&file=${file}`, true)
        .then((res) => {
          if ((res.status = 200)) {
            setImageArray(imageArray.filter((item) => item.image !== file));
          }
        })
        .catch((err) => console.log("Fetching error while updating data", err));
    }
  };

  const [initialValues, setInitialValues] = useState({
    fullname: "",
    email: null,
    mobile_num: "",
    country_code: "",
    gender: "",
    birth_date: "",
    seeing_interest: "",
    orientation_id: "",
    basic_id: [],
    my_interests: "",
    looking_for: "",
    about_me: "",
    latitude: "",
    longitude: "",
    // device_token: "",
    // login_type: "2",
    is_show_my_interests: 0,
    is_verified: 0,
    // is_verified_type: "",
    is_notification: 0,
    is_block: 0,
    is_video_call: 0,
    is_fake: 1,
    images: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      let basic_ids = defaultBasic.map((item) => item.value).join(",");
      let interest_ids = defaultInterest.map((item) => item.value).join(",");
      if (!defaultBasic?.length) {
        formik.setFieldError("basic_id", "Basic are required");
      }
      if (!defaultInterest?.length) {
        formik.setFieldError("my_interests", "My interests are required");
      }
      if (uploadMultImg === undefined && imageArray.length === 0) {
        formik.setFieldError("images", "Image Required");
      }
      if (uploadMultImg !== undefined) {
        if (uploadMultImg === undefined) {
          for (let i = 0; i < imageArray.length; i++) {
            formData.append("images", imageArray[i]);
          }
        } else {
          for (let i = 0; i < uploadMultImg.length; i++) {
            formData.append("images", uploadMultImg[i]);
          }
        }
      }
      formData.append("about_me", values?.about_me);
      formData.append("fullname", values?.fullname);
      formData.append("email", values.email);
      formData.append("mobile_num", values.mobile_num);
      formData.append("country_code", values.country_code);
      formData.append("gender", values.gender);
      formData.append("birth_date", values.birth_date);
      formData.append("seeing_interest", values.seeing_interest);
      formData.append("orientation_id", values.orientation_id);
      formData.append("basic_id", basic_ids);
      formData.append("my_interests", interest_ids);
      formData.append("looking_for", values.looking_for);
      formData.append("latitude", values.latitude);
      formData.append("longitude", values.longitude);
      // formData.append("device_token", values.device_token);
      // formData.append("login_type", values.login_type);
      formData.append("is_show_my_interests", values.is_show_my_interests);
      formData.append("is_verified", values.is_verified);
      formData.append("is_fake", values.is_fake);
      formData.append("is_video_call", values.is_video_call);
      formData.append("is_block", values.is_block);
      formData.append("is_notification", values.is_notification);
      // formData.append("is_verified_type", values.is_verified_type);
      let url = id === undefined ? "add-fakeuser" : `edit-user/${id}`;
      await postApi(url, formData, true, true)
        .then((res) => {
          if (res.status === 200) {
            window.location.href = "/user";
          }
          if (res.status === 422) {
            formik.setFieldError("email", res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const handleImageUpload = (e) => {
    let uploadFiles = e.target.files;
    let newUploadImg = [...selectedFiles];
    for (let i = 0; i < uploadFiles.length; i++) {
      newUploadImg.push(uploadFiles[i]);
    }
    setSelectedFiles(newUploadImg);
    setUploadMultImg(e.target.files);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="profileform">
        <div>
          <label className="popup__label">Full Name</label>
          <div className="form-input">
            <input
              name="fullname"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.fullname}
              placeholder="Enter Fullname"
              className={
                "popup__input " +
                (formik.errors.fullname && formik.touched.fullname
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.fullname && formik.errors.fullname ? (
              <p>{formik.errors.fullname}</p>
            ) : null}
          </div>
        </div>

        <div>
          <label className="popup__label">Email</label>
          <div className="form-input">
            <input
              name="email"
              type="text"
              placeholder="Enter Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.email}
              className={
                "popup__input " +
                (formik.errors.email && formik.touched.email
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.email && formik.errors.email ? (
              <p>{formik.errors.email}</p>
            ) : null}
          </div>
        </div>

        <div>
          <label className="popup__label">Mobile Number</label>
          <div className="form-input">
            <input
              name="mobile_num"
              type="text"
              placeholder="Enter Mobile Number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.mobile_num}
              className={
                "popup__input " +
                (formik.errors.mobile_num && formik.touched.mobile_num
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.mobile_num && formik.errors.mobile_num ? (
              <p>{formik.errors.mobile_num}</p>
            ) : null}
          </div>
        </div>

        <div>
          <label className="popup__label">Country</label>
          <div className="selectWrap custon position-relative">
            <select
              name="country_code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.country_code}
              className={
                "my-select " +
                (formik.errors.country_code && formik.touched.country_code
                  ? "errorinput"
                  : "")
              }
            >
              <option value="">Select Country</option>
              {country?.map((option) => (
                <option
                  key={option.id}
                  value={option.dial_code}
                  selected={option.id === formik.values?.country_code}
                >
                  {option.country}
                </option>
              ))}
            </select>
          </div>
          <div className="error">
            {formik.touched.country_code && formik.errors.country_code ? (
              <p>{formik.errors.country_code}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">Gender</label>
          <div className="selectWrap custon position-relative">
            <select
              name="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // value={formik?.values?.gender}
              className={
                "my-select " +
                (formik.errors.gender && formik.touched.gender
                  ? "errorinput"
                  : "")
              }
            >
              <option value="">Select Gender</option>
              {genders?.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  selected={option.name === formik.values?.gender}
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="error">
            {formik.touched.gender && formik.errors.gender ? (
              <p>{formik.errors.gender}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">
            Who are you interestes in seeing?
          </label>
          <div className="selectWrap custon position-relative">
            <select
              name="seeing_interest"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // value={formik?.values?.seeing_interest}
              className={
                "my-select " +
                (formik.errors.seeing_interest && formik.touched.seeing_interest
                  ? "errorinput"
                  : "")
              }
            >
              <option value="">Select Interest</option>
              {genderss?.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                  selected={option.name === formik.values?.seeing_interest}
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="error">
            {formik.touched.seeing_interest && formik.errors.seeing_interest ? (
              <p>{formik.errors.seeing_interest}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">Your sexual orientation?</label>
          <div className="selectWrap custon position-relative">
            <select
              name="orientation_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.orientation_id}
              className={
                "my-select " +
                (formik.errors.orientation_id && formik.touched.orientation_id
                  ? "errorinput"
                  : "")
              }
            >
              <option value="">Select Orientation</option>
              {orientations?.map((option) => (
                <option key={option.id} value={option.id} selected={formik?.values?.orientation_id === option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="error">
            {formik.touched.orientation_id && formik.errors.orientation_id ? (
              <p>{formik.errors.orientation_id}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">Birth Date</label>
          <div className="form-input">
            <input
              name="birth_date"
              type="date"
              max={maxDate}
              min={minDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.birth_date}
              className={
                "popup__input " +
                (formik.errors.birth_date && formik.touched.birth_date
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.birth_date && formik.errors.birth_date ? (
              <p>{formik.errors.birth_date}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">Looking For</label>
          <div className="selectWrap custon position-relative">
            <select
              name="looking_for"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.looking_for}
              className={"my-select " + (formik.errors.looking_for && formik.touched.looking_for ? "errorinput" : "")}
            >
              <option value="">Select Looking for</option>
              {lookigforss?.map((option) => (
                <option key={option.id} value={option.id} selected={formik?.values?.looking_for === option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="error">
            {formik.touched.looking_for && formik.errors.looking_for ? (
              <p>{formik.errors.looking_for}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">Choose your basics</label>
          <div className="my-select">
            <Select
              placeholder="Select Basic"
              name="basic_id"
              closeMenuOnSelect={false}
              components={animatedComponents}
              options={basicOptions}
              value={defaultBasic}
              onChange={(e) => {
                setDefaultBasic(e);
              }}
              isMulti
              className={
                "my-select " +
                (formik.errors.basic_id && formik.touched.basic_id
                  ? "errorinput"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.basic_id && formik.errors.basic_id ? (
              <p>{formik.errors.basic_id}</p>
            ) : null}
          </div>
        </div>
        <div className="switchWrap position-relative">
          <label className="popup__label">Choose your interests</label>
          <div className="my-select">
            <Select
              placeholder="Select Interest"
              name="my_interests"
              closeMenuOnSelect={false}
              components={animatedComponents}
              value={defaultInterest}
              onChange={(e) => {
                setDefaultInterest(e);
              }}
              isMulti
              options={interestOptions}
              className={
                "my-select " +
                (formik.errors.my_interests && formik.touched.my_interests
                  ? "errorinput"
                  : "")
              }
            />
          </div>
          <label className="switch abswitch">
            <input
              type="checkbox"
              name="is_show_my_interests"
              checked={formik?.values?.is_show_my_interests || 0}
              // value={formik?.values?[field.name]}
              onChange={(e) => {
                formik.setFieldValue(
                  "is_show_my_interests",
                  formik?.values?.is_show_my_interests === 1 ? 0 : 1
                );
              }}
            />
            <span className="slider round"></span>
          </label>
          <div className="error">
            {formik.touched.my_interests && formik.errors.my_interests ? (
              <p>{formik.errors.my_interests}</p>
            ) : null}
          </div>
        </div>

        <div>
          <label className="popup__label">About Me</label>
          <div className="form-input">
            <input
              name="about_me"
              type="text"
              placeholder="Enter About Me"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.about_me}
              className={
                "popup__input " +
                (formik.errors.about_me && formik.touched.about_me
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.about_me && formik.errors.about_me ? (
              <p>{formik.errors.about_me}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">Latitude </label>
          <div className="form-input">
            <input
              name="latitude"
              type="text"
              placeholder="Enter Lattitude"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.latitude}
              className={
                "popup__input " +
                (formik.errors.latitude && formik.touched.latitude
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.latitude && formik.errors.latitude ? (
              <p>{formik.errors.latitude}</p>
            ) : null}
          </div>
        </div>
        <div>
          <label className="popup__label">Longitude</label>
          <div className="form-input">
            <input
              name="longitude"
              type="text"
              placeholder="Enter Longitude"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.longitude}
              className={
                "popup__input " +
                (formik.errors.longitude && formik.touched.longitude
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.longitude && formik.errors.longitude ? (
              <p>{formik.errors.longitude}</p>
            ) : null}
          </div>
        </div>
        {/* <div>
          <label className="popup__label">Device Token</label>
          <div className="form-input">
            <input
              name="device_token"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.device_token}
              className={
                "popup__input " +
                (formik.errors.device_token && formik.touched.device_token
                  ? "is_invalid"
                  : "")
              }
            />
          </div>
          <div className="error">
            {formik.touched.device_token && formik.errors.device_token ? (
              <p>{formik.errors.device_token}</p>
            ) : null}
          </div>
        </div> */}
        {/* <div>
          <label className="popup__label">Login Type</label>
          <div className="selectWrap custon position-relative">
            <select
              name="login_type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.login_type}
              className={
                "my-select " +
                (formik.errors.login_type && formik.touched.login_type
                  ? "errorinput"
                  : "")
              }
            >
              <option value="">Select Login Type</option>
              {login_types?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="error">
            {formik.touched.login_type && formik.errors.login_type ? (
              <p>{formik.errors.login_type}</p>
            ) : null}
          </div>
        </div> */}
        {/* <div className="position-relative">
          <label className="popup__label">Verified Type</label>
          <div className="selectWrap custon position-relative">
            <select
              name="is_verified_type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.is_verified_type}
              className={
                "my-select " +
                (formik.errors.is_verified_type &&
                formik.touched.is_verified_type
                  ? "errorinput"
                  : "")
              }
            >
              <option value="">Select Verified Type</option>
              {login_types?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <label className="switch abswitch">
            <input
              type="checkbox"
              name="is_verified"
              checked={formik?.values?.is_verified || 0}
              // value={formik?.values?[field.name]}
              onChange={(e) => {
                formik.setFieldValue(
                  "is_verified",
                  formik?.values?.is_verified === 1 ? 0 : 1
                );
              }}
            />
            <span className="slider round"></span>
          </label>
          <div className="error">
            {formik.touched.is_verified_type &&
            formik.errors.is_verified_type ? (
              <p>{formik.errors.is_verified_type}</p>
            ) : null}
          </div>
        </div> */}
        <div>
          <div className="d-flex align-items-center mb-2">
            <p className="mb-0 marginright">Notification</p>
            <label className="switch">
              <input
                type="checkbox"
                name="is_notification"
                checked={formik?.values?.is_notification || 0}
                // value={formik?.values?[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    "is_notification",
                    formik?.values?.is_notification === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="d-flex align-items-center mb-2">
            <p className="mb-0 marginright">Block</p>
            <label className="switch">
              <input
                type="checkbox"
                name="is_block"
                checked={formik?.values?.is_block || 0}
                // value={formik?.values?[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    "is_block",
                    formik?.values?.is_block === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>

        <div>
          <div className="d-flex align-items-center mb-2">
            <p className="mb-0 marginright">Video Call</p>
            <label className="switch">
              <input
                type="checkbox"
                name="is_video_call"
                checked={formik?.values?.is_video_call || 0}
                // value={formik?.values?[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    "is_video_call",
                    formik?.values?.is_video_call === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="d-flex align-items-center mb-2">
            <p className="mb-0 marginright">Fake</p>
            <label className="switch">
              <input
                type="checkbox"
                name="is_fake"
                checked={formik?.values?.is_fake || 0}
                // value={formik?.values?[field.name]}
                onChange={(e) => {
                  formik.setFieldValue(
                    "is_fake",
                    formik?.values?.is_fake === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div></div>
        <div>
          <div className="imageclass mb-3 d-flex">
            <div>
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  multiple
                  name="images"
                  // onChange={handleImageChange}
                  //               const files = Array.from(e.target.files);
                  // const newImages = files.map(file => URL.createObjectURL(file));
                  // setImages(prevImages => [...prevImages, ...newImages]);
                  onChange={
                    // setUploadMultImg(e.target.files);
                    // setSelectedFiles(Array.from(e.target.files));
                    handleImageUpload
                  }
                />
              </div>
              <div className="clearfix error mt-2">
                {formik.touched.images && formik.errors.images ? (
                  <p> {formik.errors.images}</p>
                ) : null}
              </div>
            </div>
            <div className="image-preview-container d-flex">
              {imageArray &&
                imageArray.map((file, index) => {
                  if (file.image.endsWith(".mp4")) {
                    return (
                      <div className="btnrmvwrap" key={index}>
                        <div className="imageWrap profile mt-2 mb-2">
                          <video
                            key={index}
                            src={`${process.env.REACT_APP_UPLOADS_URL}users/${file.image}`}
                            className="image-preview"
                            controls
                          />
                        </div>
                        <button
                          className="remove-btn"
                          type="button"
                          onClick={() => handleDelete(file.image, "imageArray")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div className="btnrmvwrap">
                        <div className="imageWrap profile mt-2 mb-2">
                          <img
                            key={index}
                            src={`${process.env.REACT_APP_UPLOADS_URL}users/${file.image}`}
                            alt={`Selected file ${file}`}
                            className="image-preview"
                          />
                        </div>
                        <button
                          className="remove-btn"
                          type="button"
                          onClick={() => handleDelete(file.image, "imageArray")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  }
                })}
              {selectedFiles &&
                selectedFiles.map((file, index) => {
                  if (file.type.startsWith("video/")) {
                    return (
                      <div className="btnrmvwrap">
                        <div className="imageWrap profile mt-2 mb-2">
                          <video
                            key={index}
                            src={URL.createObjectURL(file)}
                            controls
                          />
                        </div>
                        <button
                          className="remove-btn"
                          type="button"
                          onClick={() => handleDelete(index, "selectedFiles")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div className="btnrmvwrap">
                        <div className="imageWrap profile mt-2 mb-2">
                          <img
                            key={index}
                            src={URL.createObjectURL(file)}
                            alt={`Selected file ${index + 1}`}
                            className="image-preview"
                          />
                        </div>
                        <button
                          className="remove-btn"
                          type="button"
                          onClick={() => handleDelete(index, "selectedFiles")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentcolor"
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                          </svg>
                        </button>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <section className="clearfix w-100 d-flex align-items-center justify-content-center mt-3">
          <button className="mybtn clearfix" type="submit">
            {id === undefined ? "Add User" : "Update User"}
          </button>
        </section>
      </form>
    </div>
  );
}
