
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { loginUser } from "../../Redux/reducer/auth";
import Lottie from "lottie-react";
import loginImage from "../../assets/images/auth-screen-animation.json";
import logo from "../../assets/images/belive.png";
import { postApi } from "../../Helper/api";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  // const [email, setEmail] = useState();
  // const [emailStatus, setEmailStatus] = useState(false);
  const [formdata] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [error]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required.")
      .matches(
        "^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$",
        "Invalid email address."
      ),
    password: Yup.string()
      .max(16, "Must be 16 characters or less.")
      .min(6, "Must be 6 characters or Grater.")
      .required("Password is required."),
  });

  const formik = useFormik({
    initialValues: formdata,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await postApi(`sign-in`, values).then((response) => {
        if (response?.status === 422) {
          formik.setFieldError("email", response?.data?.message);
        } else if (response?.status === 200) {
          dispatch(loginUser(response.data));
          localStorage.setItem("authentication", response?.data?.authentication);
          navigate('/dashbord');
        }
      })
    },
  });

  // const handleForgetPassword = () => {
  //   if (
  //     email !== "" &&
  //     email !== undefined &&
  //     email !== null &&
  //     emailStatus === "Success"
  //   ) {
  //     navigate(`/forgotpassword?email=${encodeURIComponent(email)}`);
  //   } else if (emailStatus !== "Success") {
  //     setError("Email Address does not exist.");
  //   } else {
  //     setError("enter email");
  //   }
  //   // Navigate to forget password page with email as a query parameter
  // };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const checkEmail = async (e) => {
  //   setEmailStatus(true);
  //   await axios
  //     .get(`${process.env.REACT_APP_BASE_URL}admin-checkemail?email=${e}`)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setEmailStatus("Success");
  //       }
  //     })
  //     .catch((err) => {
  //       setError(err.response.data.message);
  //     });
  // };

  return (
    <div className="container-login">
      <section
        className="login-section d-flex  
        align-items-center  
        justify-content-between  
        min-vh-100"
      >
        <div className="loginformWrap col-3">
          <div className="loginlogowrap d-flex justify-content-center  align-items-center mb-5">
            <div className="logo-wrap1 marginright">
              <img src={logo} alt="logo wrap" />
            </div>
            <div className="logo-content">
              <h4>Belive</h4>
            </div>
          </div>
          <div className="login-title">
            <h4>Login</h4>
          </div>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form_group">
              <input
                type="text"
                placeholder="Username"
                name="email"
                value={formik.values.email}
                // onChange={(e) => {
                //   formik.setFieldValue("email", e.target.value);
                //   setEmail(e.target.value);
                // checkEmail(e.target.value);
                // }}
                onChange={formik.handleChange}
                className={(formik.touched.email && formik.errors.email) ? "is_invalid" : ""}
              />
              {/* {emailStatus && email !== "" ? (
                  <div
                    className={`svgwrap ${emailStatus === "Success" ? "green" : ""
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentcolor"
                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                      />
                    </svg>
                  </div>
                ) : null} */}
              {formik.touched.email && formik.errors.email ? (
                <span className="invalid_txt">{formik.errors.email}</span>
              ) : null}
            </div>
            <div className="form_group mb-0">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className={(formik.touched.password && formik.errors.password) ? "is_invalid" : ""}
              />
              <span className="eye" onClick={togglePasswordVisibility}>
                {showPassword ? <FiEyeOff /> : <FiEye />}
              </span>
              {formik.touched.password && formik.errors.password ? (
                <span className="invalid_txt">{formik.errors.password}</span>
              ) : null}
            </div>
            <div className="button-login">
              <button type="submit">Login</button>
            </div>
            {/* <div className="error mt-2">
              {error && error ? <p>{error}</p> : null}
            </div> */}
          </form>
        </div>
        <div className="logincontainerwrap col-8 position-relative">
          <Lottie animationData={loginImage} loop={true} />
        </div>
      </section>
    </div>
  );
}

export default Login;
