import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { postApi } from "../../Helper/api";
import { FiEye, FiEyeOff } from "react-icons/fi";
function Signup() {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    name: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const formik = useFormik({
    initialValues: formdata,
    validationSchema: Yup.object({
      email: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("email Required"),
      name: Yup.string().required("name Required"),
      password: Yup.string().required("password Required"),
    }),
    onSubmit: async (values) => {
      const url = "add-admin";
      await postApi(url, values)
        .then((res) => {
          setSuccess(res.data.message);
          // localStorage.setItem("token", res.data.data.token);
          formik.resetForm();
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    },
  });

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    if (success) {
      timer = setTimeout(() => {
        setSuccess("");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
    return () => {
      clearTimeout(timer);
    };
  }, [error, success]);
  return (
    <section
      className="login-section d-flex  
        align-items-center  
        justify-content-center  
       "
    >
      <div className="loginformWrap w-50">
        <form onSubmit={formik.handleSubmit}>
          <div className="login-field-wrap">
            <div className="myinput">
              <input
                type="text"
                placeholder="Username"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={formik.errors.email ? "is_invalid" : ""}
              />
            </div>
          </div>
          <div className="error">
            {formik.touched.email && formik.errors.email ? (
              <p>{formik.errors.email}</p>
            ) : null}
          </div>
          <div className="login-field-wrap">
            <div className="myinput">
              <input
                type="text"
                placeholder="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className={formik.errors.name ? "is_invalid" : ""}
              />
            </div>
          </div>
          <div className="error">
            {formik.touched.name && formik.errors.name ? (
              <p>{formik.errors.name}</p>
            ) : null}
          </div>
          <div className="login-field-wrap">
            <div className="myinput">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className={formik.errors.password ? "is_invalid" : ""}
              />
              <span className="eye" onClick={toggleConfirmPasswordVisibility}>
                {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
              </span>
            </div>
          </div>
          <div className="error">
            {formik.touched.password && formik.errors.password ? (
              <p>{formik.errors.password}</p>
            ) : null}
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="mybtn">
              Create Sub Admin
            </button>
          </div>
          <div className="text-success mt-2">
            {success && success ? <p>{success}</p> : null}
          </div>
          <div className="error mt-2">
            {error && error ? <p>{error}</p> : null}
          </div>
        </form>
      </div>
    </section>
  );
}

export default Signup;
