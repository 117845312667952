import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { getApi, postApi } from "../Helper/api";
import Pagination from "./Pagination";
import Popup from "./Popup";
import MyContext from "./MyContext";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { errorNotify, successNotify } from "./SuccessErrorMessage";
export default function TableDisplay({
  tableurl,
  title,
  deleteurl,
  deleteallurl,
  category,
  formConfig,
  initialValues,
  addurl,
  updateurl,
  updatteurl,
  serachCategory,
  statusurl,
  axiosHeader,
  replyMessage,
  autoMessage,
}) {
  let permission_module;
  const location = useLocation();
  const urlName = location.pathname;
  let module = urlName.replace(/^\/|\/$/g, "");
  const user = useSelector((state) => state.authentication.user);
  const isSuperAdmin = user?.is_superadmin;
  // const permission = user && JSON.parse(user?.permission);
  // if (permission !== null) {
  //   permission_module = { ...permission[module] };
  // }
  if (isSuperAdmin === 1) {
    permission_module = {
      read: true,
      create: true,
      update: true,
      delete: true,
    };
  }

  const [data, setData] = useState();
  const [updatedata, setUpdateData] = useState({});
  const [isupdate, setIsupdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteid, setdeleteid] = useState(0);
  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = () => setShowDelete(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(
    selectedItems?.length === data?.length ? true : false
  );
  const [count, setCount] = useState(0);
  /********************** */
  // const [formData, setFormData] = useState();
  const [shows, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /****************************** */
  /*************Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /***************** */
  const [type, setType] = useState(null);
  const fetchData = async () => {
    let url = (search !== null && type !== undefined) || type !== null
      ? `${tableurl}?page=${currentPage}&search=${search}&datatype=${type}`
      : search !== null
      ? `${tableurl}?page=${currentPage}&search=${search}`
      : type !== null
      ? `${tableurl}?page=${currentPage}&datatype=${type}`
      : `${tableurl}?page=${currentPage}`;
    
    await getApi(url, true)
      .then((res) => {
        setData(res.data);
        setTotalPages(res.totalPages);
        setCount(res.count);
      })
      .catch((err) => {
        console.error("Fetching data errror", err);
        if (err?.response?.status === 403) {
          setError(err.response.data.message);
        }
      });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, type, search, selectedItems]);
  const handleDelete = async (id) => {
    await getApi(`${deleteurl}/${id}`, true)
      .then((res) => {
        if (res?.status === 200) {
          fetchData();
          errorNotify(res.message);
          handleDeleteClose();
          // fetchData();
          if (data.length === 0) {
            setCurrentPage(currentPage - 1);
          }
          setCurrentPage(currentPage);
        }
      })
      .catch((err) => console.error(err));
  };
  const highlightText = (text, search) => (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={[search]}
      autoEscape={true}
      textToHighlight={
        text !== null && text !== undefined ? text.toString() : text
      }
    />
  );
  const handleDeleteAll = async () => {
    await getApi(`${deleteallurl}/${selectedItems}`, true)
      .then((res) => {
        errorNotify(res.message);
        handleDeleteClose();
        setSelectedItems([]);
        fetchData();
        if (res?.status === 200) {
          setIsChecked(false);
        }
      })
      .catch((err) => console.error(err));
  };
  const statuscheckboxchange = (e) => {
    let i = [];
    if (e === true) {
      setIsChecked(true);
      i = data.map((item) => item.id);
    }
    if (e === false) {
      setIsChecked(false);
      i = [];
    }

    setSelectedItems(i);
  };
  const handleUpdate = async (id) => {
    await getApi(`${updateurl}/${id}`, true)
      .then((res) => {
        setUpdateData(res.data);
        setIsupdate(true);
        handleShow();
      })
      .catch((err) => console.error(err));
  };

  let options = replyMessage?.map((country) => ({
    value: country.id.toString(), // Convert ID to string
    label: country.meassage, // Use country name as label
  }));

  let lan = updatedata.language && updatedata.language.split(",");
  const convertedArray = lan && lan.map((item) => ({
    value: item,
    label: item,
  }));

  const replyi = updatedata.reply_message_id && updatedata?.reply_message_id?.split(",");

  let filteredData = [];
  const test = replyi?.map((val) => {
    let data = options?.find((vals) => vals.value === val);
    filteredData.push(data);
  });

  const autoOpt = autoMessage && autoMessage.map((country) => ({
    value: country.id.toString(), // Convert ID to string
    label: country.meassage, // Use country name as label
  }));

  const replyauto =
    updatedata.auto_message_id && updatedata?.auto_message_id?.split(",");

  const filteredDataauto = [];
  const test1 = replyauto?.map((val) => {
    let data = autoOpt?.find((vals) => vals.value === val);
    filteredDataauto.push(data);
  });
  autoOpt && autoOpt.filter((item) => replyauto?.includes(item.value));
  const value = {
    title: category,
    isupdate: isupdate,
    handleClose: handleClose,
    isShow: shows,
    formConfig: formConfig,
    url: addurl,
    data: isupdate ? updatedata : initialValues,
    filteredData: isupdate ? filteredData : [],
    filteredDataauto: isupdate ? filteredDataauto : [],
    autoOption: autoOpt,
    options: options,
    fetchData: fetchData,
    updatteurl: updatteurl,
    axiosHeader: axiosHeader,
    token: true,
    replyMessage: replyMessage,
    autoMessage: autoMessage,
    languageoptions: isupdate ? convertedArray : [],
  };

  const handleStatusChange = async (statuss, id, titless) => {
    const newdata = {
      [titless]: statuss,
    };
    await postApi(`${statusurl}/${id}`, newdata, true)
      .then((res) => {
        if (res.status === 200) {
          successNotify(res.message);
          fetchData();
        }
      })
      .catch((err) => console.error(err));
  };
  const toggleSelectItem = (itemId) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter((id) => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
    if (data?.length === data?.length) {
      setIsChecked(true);
    }
  };

  return (
    <MyContext.Provider value={value}>
      {error ? (
        <div className="alert-danger text-center alert">
          <p>{error}</p>
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between">
            <div className="title w-60 d-flex justify-content-between align-middle align-items-center">
              <h4 className="d-flex justify-content-between marginright align-items-center">
                {category}
                {"   "}
              </h4>
              {count === 1 ? (
                <h6 className="m-0">( {count} Record )</h6>
              ) : count > 0 ? (
                <h6>( {count} Records )</h6>
              ) : null}
            </div>
            <div className="d-flex justify-content-between">
              {category === "Profile" ? (
                <div className="dropdown-wrap d-flex">
                  {/* <label className='popup__label marginright'>Show</label> */}
                  <div className="shownumber custon position-relative">
                    <select
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      className="my-select"
                    >
                      <option value="null">All</option>
                      <option value="Master">Master</option>
                      <option value="Organic">Organic</option>
                      <option value="Gc">Gc</option>
                      <option value="Fc">Fc</option>
                      <option value="3rd Party">3rd Party</option>
                    </select>
                  </div>
                </div>
              ) : null}
            </div>
            {category !== "Users" && category !== "Payment" ? (
              <div className="w-50 d-flex flex-row-reverse">
                {permission_module?.create === true ? (
                  <button
                    className="mybtn"
                    onClick={() => {
                      handleShow();
                      setIsupdate(false);
                    }}
                  >{`add ${category}`}</button>
                ) : null}
                {category !== "Coin Package" &&
                category !== "Fake Video Data" &&
                category !== "Setting" &&
                category !== "More Apps" &&
                category !== "Reply Message" &&
                category !== "Auto Message" &&
                category !== "Real Video Data" ? (
                  <div className="search marginright w-50">
                    <input
                      className="search__input marginright popup__input"
                      onChange={(e) => {
                        setSearch(e.target.value); setCurrentPage(1);
                      }}
                      type="text"
                      placeholder={`Search ${serachCategory}`}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="black"
                        d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                      />
                    </svg>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <Table
            responsive="sm"
            className={`data_table ${category === "Users" ? "users" : ""}`}
          >
            <thead>
              {selectedItems.length === 0 ||
              (isChecked === true && selectedItems.length !== 0) ||
              (isChecked === false && selectedItems.length === data.length) ? (
                <tr>
                  <th>
                    {category !== "Users" && category !== "Payment" && data?.length > 0 && permission_module?.delete === true ? (
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          className="checkbox__input"
                          checked={isChecked && selectedItems?.length === data?.length}
                          onChange={() => {statuscheckboxchange(!isChecked);}}
                        />
                        <span className="checkbox__in">
                          <span className="checkbox__tick"></span>
                        </span>
                      </div>
                    ) : null}
                  </th>
                  {title && title.map((title, index) => (
                      <th key={index}>
                        {title === "category_id" ? "Category" : title}
                      </th>
                    ))}
                  {isChecked === true && selectedItems.length !== 0 ? (
                    <th>
                      <Dropdown className={`dotdisplay ${isChecked === true && selectedItems.length !== 0 ? "showw text-end" : ""}`}>
                        <Dropdown.Toggle id="dropdown-basic" className="my-dropdown">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                            <path
                              fill="currentcolor"
                              d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"
                            />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {/* <Dropdown.Item>Action</Dropdown.Item> */}
                          <button
                            className="showWrap auto"
                            type="button"
                            onClick={handleDeleteShow}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                              <path
                                fill="currentcolor"
                                d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                              />
                            </svg>
                            Delete
                          </button>
                        </Dropdown.Menu>
                      </Dropdown>
                    </th>
                  ) : category !== "Users" && category !== "Payment" && permission_module?.delete === true && permission_module?.update === true ? (
                    <th>Action</th>
                  ) : null}
                </tr>
              ) : (
                <tr className="fulllengthclass w-100">
                  <th colSpan={title.length + 1}>
                    <h6>{selectedItems.length} selected</h6>
                  </th>
                  <th>
                    <Dropdown className={`dotdisplay ${isChecked === false && selectedItems.length !== 0 ? "showw text-end" : ""}`}>
                      <Dropdown.Toggle id="dropdown-basic" className="my-dropdown">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                          <path
                            fill="currentcolor"
                            d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"
                          />
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <button className="showWrap auto" type="button" onClick={handleDeleteShow}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                              fill="currentcolor"
                              d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                            />
                          </svg>
                          Delete
                        </button>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                </tr>
              )}
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="align-middle">
                      {category !== "Users" &&
                        category !== "Payment" &&
                        permission_module?.delete && (
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              className="checkbox__input"
                              onChange={() => toggleSelectItem(row.id)}
                              checked={selectedItems.includes(row.id)}
                            />
                            <span className="checkbox__in">
                              <span className="checkbox__tick"></span>
                            </span>
                          </div>
                        )}
                    </td>
                    {title.map((title, colIndex) => (
                      <td key={colIndex} className={`align-middle ${title}`}>
                        {title === "gender" ? (
                          row[title] === 1 ? ("Male") 
                          : row[title] === 2 ? ("Female") 
                          : row[title] === 3 ? ("Others") 
                          : (row[title])
                        ) 
                        : title === "icons" ? (row.icons === null || row.icons === undefined || row.icons === "" ? null : (
                            <div className={`${category === "Looking For" ? '' : 'imageWrap'}`}>
                              <img src={`${process.env.REACT_APP_UPLOADS_URL}icons/${row.icons}`} alt={row.icons}/>
                            </div>
                          )
                        ) 
                        : title === "user name" ? (
                          row.userPayemntData.fullname
                        ) 
                        : title === "flag" ? ( row.flag === null || row.flag === undefined || row.flag === "" ? null : (
                            <div className="imageWrap">
                              <img src={`${process.env.REACT_APP_UPLOADS_URL}flags/${row.flag}`} alt="flag"/>
                            </div>
                          )
                        ) 
                        : title === "category_id" ? (row.basicCategorys.category) 
                        : title === "topup_type" ? (row[title] === 1 ? ("superlike")  : row[title] === 2 ? ("boost")  : ("diamond")) 
                        : ["status", "is_top_up"].includes(title) ? (
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={row[title] || false}
                              onChange={() => handleStatusChange(row[title] === 1 ? 0 : 1, row.id, title)}
                            />
                            <span className="slider round"></span>
                          </label>
                        ) : (
                          highlightText(row[title], search)
                        )}
                      </td>
                    ))}
                    {category !== "Users" && category !== "Payment" && selectedItems.length === 0 ? (
                      <td className="align-middle">
                        <div className="d-flex">
                          {permission_module?.update && (
                            <button className="showWrap marginright" onClick={() => handleUpdate(row.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                  fill="currentColor"
                                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                />
                              </svg>
                            </button>
                          )}
                          {permission_module?.delete && (
                            <button className="showWrap" onClick={() => {handleDeleteShow(); setdeleteid(row.id);}}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                  fill="currentColor"
                                  d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={title.length + 2} className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : null}
          <Modal show={showDelete} onHide={handleDeleteClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this {category}.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="mybtn black"
                onClick={handleClose}
              >
                Cancel
              </Button>
              {selectedItems.length === 0 ? (
                <Button variant="primary" className="mybtn" onClick={() => handleDelete(deleteid)}>
                  Delete
                </Button>
              ) : (
                <Button variant="primary" className="mybtn" onClick={handleDeleteAll}>
                  Delete
                </Button>
              )}
            </Modal.Footer>
          </Modal>
          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNext={handleNextPage}
              onPrev={handlePrevPage}
              handlePageChange={handlePageChange}
            />
          ) : null}

          {shows && <Popup />}
        </div>
      )}
    </MyContext.Provider>
  );
}
