import React, { useContext, useEffect, useState } from "react";
import { postApi } from "../Helper/api";
import { useFormik } from "formik";
import MyContext from "./MyContext";
import { errorNotify, successNotify } from "./SuccessErrorMessage";

export default function DynamicForm() {
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(MyContext);
  const [file, setFile] = useState();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [meter, setMeter] = useState(0);
  const [kilometer, setKiloMeter] = useState(0);
  const id = context.data && context.data.id;
  useEffect(() => {
    if (context.data && context.data.mile) {
      setMeter(context.data.mile * 1609.34);
      setKiloMeter(context.data.mile * 1.60934);
    }
  }, [context.data]);
  const handleVisible = () => {
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 2000);
  };

  const formik = useFormik({
    initialValues: context.data,
    validationSchema: context.formConfig.validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let msg;
      if (values.meassage !== undefined) {
        msg = values.meassage;
        if (msg[msg.length - 1] === ",") {
          msg = msg.slice(0, -1);
        }
        values = {
          ...values,
          meassage: msg.split(","),
        };
      }
      if (values.status) {
        values = {
          ...values,
          status: values.status.toString(),
        };
      }
      let uurl = context.isupdate ? `${context.updatteurl}/${id}` : context.url;
      setIsLoading(true);
      await postApi(uurl, values, true, context.axiosHeader)
        .then((res) => {
          if (res.status === 200) {
            successNotify(res.message);
            handleVisible();
            formik.resetForm();
            context.fetchData();
            context.handleClose();
          }
          if (res.status === 422) {
            errorNotify(res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  });

  const handleMile = (e) => {
    const mileValue = e.target.value;
    formik.setFieldValue("mile", mileValue);
    setKiloMeter(mileValue * 1.60934);
    setMeter(mileValue * 1609.34);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={
        context.title === "Profile" || context.title === "Admin Setting"
          ? "profileform d-flex justify-content-between"
          : ""
      }
    >
      {context.formConfig.fields.map((field) => (
        <div key={field.name}>
          <label className="popup__label">{field.label}</label>
          {field.type === "select" ? (
            <div className="selectWrap mb-3">
              <select
                name={field.name}
                onChange={formik.handleChange}
                className={
                  "my-select " + (formik.errors[field.name] ? "is_invalid" : "")
                }
                value={formik.values[field.name] || ""}
              >
                <option value="">Select {field.label}</option>
                {field.options &&
                  field.options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
          ) : field.type === "file" ? (
            <div className="imageclass mb-3 d-flex">
              <div>
                <div className="imageWrapcustum marginright">
                  <input
                    type="file"
                    name={field.name}
                    onChange={(e) => {
                      formik.setFieldValue(field.name, e.target.files[0]);
                      setFile(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </div>
                <div className="error mt-2">
                  {formik.touched[field.name] && formik.errors[field.name] ? (
                    <p> {formik.errors[field.name]}</p>
                  ) : null}
                </div>
              </div>
              {file ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img src={file} alt="file preview" />
                </div>
              ) : context.data.icons ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}icons/${formik.values.icons}`}
                    alt={formik.values.icon}
                  />
                </div>
              ) : context.data.flag ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}flags/${formik.values.flag}`}
                    alt={formik.values.flag}
                  />
                </div>
              ) : context.data.logo ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <img
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}logos/${formik.values.logo}`}
                    alt={formik.values.logo}
                  />
                </div>
              ) : context.data.video ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <video
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}videos/${formik.values.video}`}
                    alt={formik.values.video}
                    controls
                  />
                </div>
              ) : null}
            </div>
          ) : field.type === "video" ? (
            <div className="imageclass mb-3 d-flex">
              <div className="imageWrapcustum marginright">
                <input
                  type="file"
                  name={field.name}
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => {
                    formik.setFieldValue(field.name, e.target.files[0]);
                    setFile(e.target.files[0]);
                  }}
                />
              </div>
              {file ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <video src={URL.createObjectURL(file)} controls />
                </div>
              ) : context.data.video ? (
                <div className="imageWrap profile mt-2 mb-2">
                  <video
                    className="img-thumbnail"
                    src={`${process.env.REACT_APP_UPLOADS_URL}videos/${formik.values.video}`}
                    alt={formik.values.video}
                    controls
                  />
                </div>
              ) : null}
            </div>
          ) : field.type === "switch" ? (
            <label className="switch">
              <input
                type="checkbox"
                name={field.name}
                checked={formik.values[field.name] || 0}
                onChange={(e) => {
                  formik.setFieldValue(
                    field.name,
                    formik.values[field.name] === 1 ? 0 : 1
                  );
                }}
              />
              <span className="slider round"></span>
            </label>
          ) : field.type === "textarea" ? (
            <div className="form-input">
              <textarea
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "is_invalid" : "")
                }
              />
            </div>
          ) : (
            <div className="form-input">
              <input
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formik.values[field.name]}
                onChange={(e) =>
                  field.name === "mile" ? handleMile(e) : formik.handleChange(e)
                }
                className={
                  "popup__input " +
                  (formik.errors[field.name] ? "is_invalid" : "")
                }
              />
              {field.name === "mile" ? (
                <p>
                  <b>Km :</b> {kilometer.toFixed(2)} <b> Meter :</b>{" "}
                  {meter.toFixed(2)}
                </p>
              ) : null}
              <div className="error">
                {formik.touched[field.name] && formik.errors[field.name] ? (
                  <p> {formik.errors[field.name]}</p>
                ) : null}
              </div>
            </div>
          )}
        </div>
      ))}
      {error && visibleAlert ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : null}
      {success && visibleAlert ? (
        <div className="alert alert-success" role="alert">
          {success}
        </div>
      ) : null}
      <div className="d-flex justify-content-center ">
        <button
          className="mybtn d-flex justify-content-center"
          type="submit"
          // disabled={isLoading}
        >
          <span className={"loader-1 marginright " + (isLoading ? "show" : "")}>
            {" "}
          </span>
          {context.isupdate
            ? `update ${context.title}`
            : isLoading === true
            ? "Loading"
            : `add ${context.title}`}
        </button>
      </div>
    </form>
  );
}
