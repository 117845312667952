import React from "react";
import ReactDOM from "react-dom/client";
// --- Router-dom ---
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// --- Import Redux ---
import { Provider } from "react-redux";
import store from "./Redux/store";
// --- Import CSS ---
import "./index.css";
// --- Import Components ---
import SignIn from "./Components/Auth/SignIn";
import SignUp from "./Components/Auth/Signup";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import Layout from "./Layout";
import Users from "./Components/Users";
import SplashUser from "./Components/SplashUser";
import Country from "./Components/Country";
import Dashboard from "./Components/Dashboard";
import UserForm from "./Components/UserForm";
import CategoryBasics from "./Components/CategoryBasics";
import Basics from "./Components/Basics";
import Interests from "./Components/Interests";
import LookingFor from "./Components/LookingFro";
import Orientations from "./Components/Orientation";
import Skus from "./Components/Sku";
import AdminSetting from "./Components/AdminSetting";
import Topups from "./Components/Topups";
import TopupCategory from "./Components/TopupCategory";
import Package from "./Components/Packages";
import Plans from "./Components/Plans";
import UserDetail from "./Components/UserDetail";
// import SettingPermission from "./Components/SettingPermission";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Provider store={store}>
        <SignIn />
      </Provider>
    ),
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/dashbord",
    element: (
      <Layout>
        <Dashboard />
      </Layout>
    ),
  },
  {
    path: "/country",
    element: (
      <Layout>
        <Country />
      </Layout>
    ),
  },
  {
    path: "/categorybasics",
    element: (
      <Layout>
        <CategoryBasics />
      </Layout>
    ),
  },
  {
    path: "/adminsetting",
    element: (
      <Layout>
        <AdminSetting />
      </Layout>
    ),
  },
  {
    path: "/sku",
    element: (
      <Layout>
        <Skus />
      </Layout>
    ),
  },
  {
    path: "/basics",
    element: (
      <Layout>
        <Basics />
      </Layout>
    ),
  },
  {
    path: "/orientations",
    element: (
      <Layout>
        <Orientations />
      </Layout>
    ),
  },
  {
    path: "/interests",
    element: (
      <Layout>
        <Interests />
      </Layout>
    ),
  },
  {
    path: "/lookingfor",
    element: (
      <Layout>
        <LookingFor />
      </Layout>
    ),
  },
  {
    path: "/topup",
    element: (
      <Layout>
        <Topups />
      </Layout>
    ),
  },
  {
    path: "/package",
    element: (
      <Layout>
        <Package />
      </Layout>
    ),
  },
  {
    path: "/plans",
    element: (
      <Layout>
        <Plans />
      </Layout>
    ),
  },
  {
    path: "/topupcategory",
    element: (
      <Layout>
        <TopupCategory />
      </Layout>
    ),
  },
  {
    path: "/user",
    element: (
      <Layout>
        <Users />
      </Layout>
    ),
  },
  {
    path: "/splash-user",
    element: (
      <Layout>
        <SplashUser />
      </Layout>
    ),
  },
  {
    path: "/user/:id",
    element: (
      <Layout>
        <UserDetail />
      </Layout>
    ),
  },
  {
    path: "/add-user",
    element: (
      <Layout>
        <UserForm />
      </Layout>
    ),
  },
  {
    path: "/edit-user/:id",
    element: (
      <Layout>
        <UserForm />
      </Layout>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
